import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetOperatorBundles() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [operatorChart, setOperatorChart] = useState([]);
  const [operatorSummary, setOperatorSummary] = useState({});

  const getOperatorBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getOperatorBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setOperatorChart(fetch_output.chart_data);
            setOperatorSummary(fetch_output.summary_data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get operator bundles",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Operator Bundles), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getOperatorBundles, operatorChart, operatorSummary };
}

export default useGetOperatorBundles;
