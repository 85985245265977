import { Flex } from "antd";
import DataTable from "../../common/DataTable";
import FunctionalButton from "../../common/FunctionalButton";
import { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { render } from "@antv/g2";

export default function MechanicalTable({
  tableData,
  handleEdit,
  handleDelete,
  deleteLoading,
}) {
  const [loadRowKey, setLoadRowKey] = useState(null);
  const MECHANICAL_TABLE_COLUMN = [
    {
      title: "No. ",
      dataIndex: "number",
      key: "number",
      width: 50,
    },
    {
      title: "Heat No",
      dataIndex: "heat_no",
      key: "heat_no",
      width: 100,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 80,
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      width: 80,
    },
    {
      title: "Rebar Production Date",
      dataIndex: "rebar_production_date",
      key: "rebar_production_date",
      width: 180,
    },
    {
      title: "Size (mm)",
      dataIndex: "size",
      key: "size",
      width: 110,
    },
    {
      title: "Length (mm)",
      dataIndex: "length",
      key: "length",
      width: 110,
    },
    {
      title: "Bend Test",
      dataIndex: "bend_test",
      key: "bend_test",
      width: 110,
      render: (bend_test) => {
        return (
          <span
            style={{ color: bend_test == "PASS" ? "green" : "red" }}
            children={bend_test}
          />
        );
      },
    },
    {
      title: "Rebend Test",
      dataIndex: "rebend_test",
      key: "rebend_test",
      width: 110,
      render: (rebend_test) => {
        return (
          <span
            style={{ color: rebend_test == "PASS" ? "green" : "red" }}
            children={rebend_test}
          />
        );
      },
    },
    {
      title: "Yield (N/mm2)",
      dataIndex: "yield",
      key: "yield",
      width: 140,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.yield_pass ? "red" : "green" }}
            children={record.yield}
          />
        );
      },
    },
    {
      title: "Tensile (N/mm2)",
      dataIndex: "tensile",
      key: "tensile",
      width: 140,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.tensile_pass ? "red" : "green" }}
            children={record.tensile}
          />
        );
      },
    },
    {
      title: "Elongation (Agt %)",
      dataIndex: "elongation",
      key: "elongation",
      width: 140,
      render: (_, record) => {
        return (
          <span
            style={{
              color: record.elongation_pass ? "red" : "green",
            }}
            children={record.elongation}
          />
        );
      },
    },
    {
      title: "Ratio (TS/YS)",
      dataIndex: "ratio",
      key: "ratio",
      width: 140,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.ratio_pass ? "red" : "green" }}
            children={record.ratio}
          />
        );
      },
    },
    {
      title: "Weight (kg/m)",
      dataIndex: "weight",
      key: "weight",
      width: 140,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.weight_pass ? "red" : "green" }}
            children={record.weight}
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 160,
      render: (_, record) => {
        const updatedRecord = {
          ...record,
          rebar_production_date: record.rebar_production_date
            ? dayjs(record.rebar_production_date, "YYYY-MM-DD")
            : null,
        };
        return (
          <Flex gap="small">
            <FunctionalButton
              buttonClickFunction={() => handleEdit(updatedRecord)}
              type=""
              width="40px"
              icon={<EditOutlined />}
              disabled={loadRowKey == record.key && deleteLoading}
            />
            <FunctionalButton
              buttonClickFunction={() => {
                handleDelete(record.key);
                setLoadRowKey(record.key);
              }}
              show_confirmation={true}
              confirmation_message={{
                title: "Confirmation",
                content:
                  "Material properties will be permanently deleted. Are you sure you want to proceed?",
              }}
              danger={true}
              width="40px"
              icon={<DeleteOutlined />}
              trigger_loading={loadRowKey == record.key && deleteLoading}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={MECHANICAL_TABLE_COLUMN}
      table_data={tableData}
      pageSize={10}
    />
  );
}
