import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetRebarInfo() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState([]);
  const [rebarInfo, setRebarInfo] = useState({});
  const [userCount, setUserCount] = useState();

  const getRebarInfo = useCallback(
    (id, navigateBack, setSelectedImage) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "rebar_verification/getRebarInfo",
        params: {
          rebar_id: id,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            setRebarInfo(fetch_ouput.data);
            setUserCount(fetch_ouput.user_count);
            setImageSrc(fetch_ouput.image_src);
            setSelectedImage(fetch_ouput.image_src[0]);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_ouput.error,
            });
            navigateBack();
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get rebar info",
          });
          navigateBack();
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content: "Failed to Respond (Rebar Info), Please Contact Admin",
          });
          navigateBack();
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getRebarInfo,
    imageSrc,
    rebarInfo,
    userCount,
    isLoading,
    setUserCount,
  };
}

export default useGetRebarInfo;
