import { IndexContext } from "../../context/IndexContext";
import useFetch from "../../hooks/useFetch";
import { useCallback, useContext, useState } from "react";

function useGetMillCertFileList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [fileListLoading, setFileListLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const getMillCertFileList = useCallback(
    (key, setFileModal) => {
      setFileListLoading(true);
      fetchWrapper({
        endpoint_url: "delivery_order/getMillCertFileList",
        params: {
          key: key,
        },
        setLoading: setFileListLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setFileList(fetch_output.data);
            setFileModal(true);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.message,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get mill cert file list",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Get Mill Cert File List), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getMillCertFileList, fileList, fileListLoading };
}

export default useGetMillCertFileList;
