import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Flex } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";

export default function CustomRangePicker({
  startDateSelection,
  setStartDateSelection,
  endDateSelection,
  setEndDateSelection,
  disabled,
  timePicker = "date",
  search = false,
  showTime = false,
  onFilterClick,
}) {
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;

      const startOf = (type) => (date) => date ? date.startOf(type) : null;
      const endOf = (type) => (date) => date ? date.endOf(type) : null;

      switch (timePicker) {
        case "week":
          setStartDateSelection(startOf("week")(start));
          setEndDateSelection(endOf("week")(end));
          break;
        case "month":
          setStartDateSelection(startOf("month")(start));
          setEndDateSelection(endOf("month")(end));
          break;
        case "year":
          setStartDateSelection(startOf("year")(start));
          setEndDateSelection(endOf("year")(end));
          break;
        default:
          setStartDateSelection(showTime ? start : startOf("day")(start));
          setEndDateSelection(showTime ? end : endOf("day")(end));
      }
    }
  };

  return (
    <Flex gap="small">
      <DatePicker.RangePicker
        format={
          timePicker === "week"
            ? "YYYY-wo"
            : timePicker === "month"
            ? "YYYY-MM"
            : timePicker === "year"
            ? "YYYY"
            : showTime
            ? "DD/MM/YYYY HH:mm:ss"
            : "DD/MM/YYYY"
        }
        value={[startDateSelection, endDateSelection]}
        allowClear={false}
        showTime={showTime}
        picker={timePicker}
        onChange={handleDateChange}
        disabledDate={(current) => current && current > dayjs()}
        disabled={disabled}
        inputReadOnly={true}
        needConfirm={false}
      />

      {search && (
        <Button
          icon={<SearchOutlined />}
          onClick={onFilterClick}
          disabled={disabled}
        />
      )}
    </Flex>
  );
}
