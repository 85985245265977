import DataTable from "../../common/DataTable";
import { Flex } from "antd";
import FunctionalButton from "../../common/FunctionalButton";
import { EditOutlined } from "@ant-design/icons";

export default function MechanicalManagementTable({ tableData, handleEdit }) {
  const MECHANICAL_MIN_MAX_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Mechanical Properties",
      dataIndex: "mechanical_name",
      key: "mechanical_name",
      width: 200,
    },
    {
      title: "Abbreviation",
      dataIndex: "mechanical_short_name",
      key: "mechanical_short_name",
      width: 200,
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      width: 140,
    },
    {
      title: "Minimum",
      dataIndex: "minimum",
      key: "minimum",
      width: 140,
    },
    {
      title: "Maximum",
      dataIndex: "maximum",
      key: "maximum",
      width: 140,
    },
    {
      title: "Last Update By",
      dataIndex: "last_update_by",
      key: "last_update_by",
      width: 140,
    },
    {
      title: "Last Update Date Time",
      dataIndex: "last_update_date_time",
      key: "last_update_date_time",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 160,
      render: (_, record) => {
        return (
          <Flex gap="small">
            <FunctionalButton
              buttonClickFunction={() => handleEdit(record)}
              type=""
              width="40px"
              icon={<EditOutlined />}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={MECHANICAL_MIN_MAX_COLUMN}
      table_data={tableData}
      pageSize={10}
    />
  );
}
