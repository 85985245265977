import {
  AuditOutlined,
  CalculatorOutlined,
  CameraOutlined,
  DownloadOutlined,
  FileMarkdownOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FundProjectionScreenOutlined,
  GoldOutlined,
  LineChartOutlined,
  MergeOutlined,
  QrcodeOutlined,
  SettingOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Flex } from "antd";
import { ignoreLabels, userAccessLabel } from "../configs/configs.js";
import { generateRoutes } from "../routes/AutoGenerateRoutes.js";

const header_height = 58;

const menuLabelIcon = {
  test: CameraOutlined,
  camera_test: CameraOutlined,
  qr_scanner_test: QrcodeOutlined,
  download_test: DownloadOutlined,
  table_data_test: TableOutlined,
  ai_rebar_count: CameraOutlined,
  bundle_list: FileTextOutlined,
  analytics: LineChartOutlined,
  dashboard: FundProjectionScreenOutlined,
  statistics: CalculatorOutlined,
  traceability: MergeOutlined,
  digital_mill_cert: FileMarkdownOutlined,
  do_progress: GoldOutlined,
  delivery_order: AuditOutlined,
  material_properties: FileProtectOutlined,
  configuration: SettingOutlined,
};

const menuLabelSort = [
  "test",
  "ai_rebar_count",
  "bundle_list",
  "analytics",
  "digital_mill_cert",
  "configuration",
];

export default function useNavMenu(authenticationState) {
  const user_type = authenticationState.user_type;
  const routes = generateRoutes(ignoreLabels);

  const menuLabel = (Icon, text, style = {}) => {
    return (
      <Flex align="center" justify="start" style={style}>
        <Icon style={{ marginRight: 12 }} />
        {text}
      </Flex>
    );
  };

  const userAccess = (allowUserType) => {
    return allowUserType.includes(user_type);
  };

  const organizeRoutes = (routes) => {
    const groupedRoutes = new Map();
    const singleRoute = new Map();

    routes.forEach((route) => {
      const path = route.path.split("/").slice(1);
      const parentKey = path[0];

      if (!groupedRoutes.has(parentKey)) {
        if (path.length > 1) {
          groupedRoutes.set(parentKey, []);
        } else {
          singleRoute.set(parentKey, path);
        }
      }

      if (path.length > 1) {
        groupedRoutes.get(parentKey).push(path);
      }
    });

    const menuLabel = createMenuLabel(
      singleRoute,
      groupedRoutes,
      userAccessLabel,
      menuLabelIcon
    );

    return sortMenuLabels(menuLabel, menuLabelSort);
  };

  const sortMenuLabels = (menus, sortOrder) => {
    const sortIndex = new Map(sortOrder.map((key, index) => [key, index]));

    return menus.sort((a, b) => {
      const aIndex = sortIndex.get(a.key) ?? Infinity;
      const bIndex = sortIndex.get(b.key) ?? Infinity;
      return aIndex - bIndex;
    });
  };

  const createMenuLabel = (
    singleRoute,
    groupRouteMap,
    user_access = [],
    menu_icon = []
  ) => {
    const formatKey = (key) =>
      key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    const menus = [];

    singleRoute.forEach((route, key) => {
      if (!userAccess(user_access[key] || [])) {
        const formatLabel = formatKey(key);
        menus.push({
          label: menuLabel(menu_icon[key], formatLabel, {
            paddingInline: 8,
            height: header_height,
          }),
          key: key,
        });
      }
    });

    groupRouteMap.forEach((routes, key) => {
      if (!userAccess(user_access[key] || [])) {
        const formatLabel = formatKey(key);

        const children = routes
          .filter((childRoute) => childRoute[0] == key)
          .map((childRoute) => {
            const childKey = childRoute.pop();
            const formatChildKey = formatKey(childKey);

            if (key == "test") {
              console.log("Icon", menu_icon[childKey]);
            }
            if (!userAccess(user_access[childKey] || [])) {
              return {
                label: menuLabel(menu_icon[childKey], formatChildKey, {
                  marginRight: 12,
                }),
                key: childKey,
              };
            }
          });

        menus.push({
          label: menuLabel(menu_icon[key], formatLabel, {
            paddingInline: 8,
            height: header_height,
          }),
          key: key,
          children: children,
        });
      }
    });

    return menus;
  };

  const NavMenu = user_type != null && organizeRoutes(routes);

  return [NavMenu, header_height];
}
