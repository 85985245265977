import { Button, Card, Flex, Tabs } from "antd";
import { useEffect, useState } from "react";
import useGetUserList from "../../functions/Configuration/user_management/getUserList";
import UserManagementTable from "../../components/configuration/user_management/UserManagementTable";
import { PlusOutlined } from "@ant-design/icons";
import UserManagementForm from "../../components/configuration/user_management/UserManagementForm";
import ProductManagementTable from "../../components/configuration/product_management/ProductManagementTable";
import useGetProductList from "../../functions/Configuration/product_management/getProductList";
import MechanicalManagementTable from "../../components/configuration/mechanical_management/MechanicalManagementTable";
import ChemicalManagementTable from "../../components/configuration/chemical_management/ChemicalManagementTable";
import useGetMechanicalMinMaxList from "../../functions/Configuration/mechanical_management/getMechanicalMinMaxList";
import useGetChemicalMinMaxList from "../../functions/Configuration/chemical_management/getChemicalMinMaxList";
import MechanicalManagementForm from "../../components/configuration/mechanical_management/MechanicalManagementForm";
import ChemicalManagementForm from "../../components/configuration/chemical_management/ChemicalManagementForm";
import useInsertUser from "../../functions/Configuration/user_management/insertUser";
import useUpdateUser from "../../functions/Configuration/user_management/updateUser";
import useRemoveUser from "../../functions/Configuration/user_management/removeUser";
import useUpdateChemicalMinMax from "../../functions/Configuration/chemical_management/updateChemicalMinMax";
import useUpdateMechanicalMinMax from "../../functions/Configuration/mechanical_management/updateMechanicalMinMax";
import MillCertManagementTable from "../../components/configuration/mill_cert_management/MillCertManagementTable";
import MillCertManagementForm from "../../components/configuration/mill_cert_management/MillCertManagementForm";
import useGetMillCertList from "../../functions/Configuration/mill_cert_management/getMillCertList";
import useUpdateMillCert from "../../functions/Configuration/mill_cert_management/updateMillCert";
import useGetWeightMinMaxList from "../../functions/Configuration/weight_management/getWeightMinMaxList";
import useUpdateWeightMinMax from "../../functions/Configuration/weight_management/updateWeightMinMax";
import WeightManagementTable from "../../components/configuration/weight_management/WeightManagementTable";
import WeightManagementForm from "../../components/configuration/weight_management/WeightManagementForm";
import CustomerManagementTable from "../../components/configuration/customer_management/CustomerManagementTable";
import BarMarkManagementTable from "../../components/configuration/bar_mark_management/BarMarkManagementTable";
import CustomerManagementForm from "../../components/configuration/customer_management/CustomerManagementForm";
import BarMarkManagementForm from "../../components/configuration/bar_mark_management/BarMarkManagementForm";
import useGetBarMarkList from "../../functions/Configuration/bar_mark_management/getBarMarkList";
import useInsertBarMark from "../../functions/Configuration/bar_mark_management/insertBarMark";
import useUpdateBarMark from "../../functions/Configuration/bar_mark_management/updateBarMark";
import useRemoveBarMark from "../../functions/Configuration/bar_mark_management/removeBarMark";
import useGetCustomerList from "../../functions/Configuration/customer_management/getCustomerList";
import useInsertCustomer from "../../functions/Configuration/customer_management/insertCustomer";
import useUpdateCustomer from "../../functions/Configuration/customer_management/updateCustomer";
import useRemoveCustomer from "../../functions/Configuration/customer_management/removeCustomer";

const MANAGEMENT_TAB = [
  {
    label: "User Management",
    key: "user_management",
  },
  {
    label: "Product Management",
    key: "product_management",
  },
  {
    label: "Mill Cert Management",
    key: "mill_cert_management",
  },
  { label: "Mechanical Management", key: "mechanical_management" },
  {
    label: "Weight Management",
    key: "weight_management",
  },
  {
    label: "Chemical Management",
    key: "chemical_management",
  },
  {
    label: "Customer Management",
    key: "customer_management",
  },
  {
    label: "Bar Mark Management",
    key: "bar_mark_management",
  },
];

const ACTIVE_ADD_BUTTON = [
  "user_management",
  "customer_management",
  "bar_mark_management",
];

export default function ConfigurationPage() {
  // For handle active tab
  const [activeTab, setActiveTab] = useState("user_management");
  // For change tab
  const onTabChange = (value) => setActiveTab(value);

  // For handle each tab Modal Form
  const [activeModal, setActiveModal] = useState(false);
  // For handle form loading
  const [formLoading, setFormLoading] = useState(false);
  // For handle each tab Edit data
  const [activeEditData, setActiveEditData] = useState(null);

  // For UserManagement tab api call
  const { getUserList, userList, userListLoading } = useGetUserList();
  const { insertUser } = useInsertUser();
  const { updateUser } = useUpdateUser();
  const { removeUser, userDeleteLoading } = useRemoveUser();

  // For ProductManagement tab api call
  const { getProductList, productList, productListLoading } =
    useGetProductList();

  // For MechanicalManagement tab api call
  const {
    getMechanicalMinMaxList,
    mechanicalMinMaxList,
    mechanicalMinMaxLoading,
  } = useGetMechanicalMinMaxList();
  const { updateMechanicalMinMax } = useUpdateMechanicalMinMax();

  // For ChemicalManagement tab api call
  const { getChemicalMinMaxList, chemicalMinMaxList, chemicalMinMaxLoading } =
    useGetChemicalMinMaxList();
  const { updateChemicalMinMax } = useUpdateChemicalMinMax();

  // For Mill Cert Management tab api call
  const { getMillCertList, millCertList, millCertLoading } =
    useGetMillCertList();
  const { updateMillCert } = useUpdateMillCert();

  // For Weight Managemnet tab api call
  const { getWeightMinMaxList, weightMinMaxList, weightMinMaxLoading } =
    useGetWeightMinMaxList();
  const { updateWeightMinMax } = useUpdateWeightMinMax();

  // For Bar Mark Management tab api call
  const { getBarMarkList, barMarkList, barMarkLoading } = useGetBarMarkList();
  const { insertBarMark } = useInsertBarMark();
  const { updateBarMark } = useUpdateBarMark();
  const { removeBarMark, barMarkDeleteLoading } = useRemoveBarMark();

  // For Customer Management tab api call
  const { getCustomerList, customerList, customerLoading } =
    useGetCustomerList();
  const { insertCustomer } = useInsertCustomer();
  const { updateCustomer } = useUpdateCustomer();
  const { removeCustomer, customerDeleteLoading } = useRemoveCustomer();

  const handleEditModalOpen = (value) => {
    setActiveEditData(value);
    setActiveModal(true);
  };

  // Handlers for each tab
  const handlers = {
    user_management: {
      addOpen: () => setActiveModal(true),
      editOpen: (value) => handleEditModalOpen(value),
      addSubmit: (value) =>
        insertUser(value, getUserList, setFormLoading, setActiveModal),
      editSubmit: (value, key) =>
        updateUser(value, key, getUserList, setFormLoading, setActiveModal),
      deleteSubmit: (key) => removeUser(key, getUserList),
    },
    product_management: {},
    mill_cert_management: {
      editOpen: (value) => handleEditModalOpen(value),
      editSubmit: (value, key) =>
        updateMillCert(
          value,
          key,
          getMillCertList,
          setFormLoading,
          setActiveModal
        ),
    },
    mechanical_management: {
      editOpen: (value) => handleEditModalOpen(value),
      editSubmit: (value, key) =>
        updateMechanicalMinMax(
          value,
          key,
          getMechanicalMinMaxList,
          setFormLoading,
          setActiveModal
        ),
    },
    weight_management: {
      editOpen: (value) => handleEditModalOpen(value),
      editSubmit: (value, key) =>
        updateWeightMinMax(
          value,
          key,
          getWeightMinMaxList,
          setFormLoading,
          setActiveModal
        ),
    },
    chemical_management: {
      editOpen: (value) => handleEditModalOpen(value),
      editSubmit: (value, key) =>
        updateChemicalMinMax(
          value,
          key,
          getChemicalMinMaxList,
          setFormLoading,
          setActiveModal
        ),
    },
    customer_management: {
      addOpen: () => setActiveModal(true),
      editOpen: (value) => handleEditModalOpen(value),
      addSubmit: (value) =>
        insertCustomer(value, getCustomerList, setFormLoading, setActiveModal),
      editSubmit: (value, key) =>
        updateCustomer(
          value,
          key,
          getCustomerList,
          setFormLoading,
          setActiveModal
        ),
      deleteSubmit: (key) => removeCustomer(key, getCustomerList),
    },
    bar_mark_management: {
      addOpen: () => setActiveModal(true),
      editOpen: (value) => handleEditModalOpen(value),
      addSubmit: (value) =>
        insertBarMark(value, getBarMarkList, setFormLoading, setActiveModal),
      editSubmit: (value, key) =>
        updateBarMark(value, getBarMarkList, setFormLoading, setActiveModal),
      deleteSubmit: (key) => removeBarMark(key, getBarMarkList),
    },
  };

  // Function to handle the AddOpen action based on active tab
  const handleAddOpen = () => {
    const handler = handlers[activeTab];
    if (handler && handler.addOpen) handler.addOpen();
  };

  // Function to handle the EditOpen action based on active tab
  const handleEditOpen = (value) => {
    const handler = handlers[activeTab];
    if (handler && handler.editOpen) handler.editOpen(value);
  };

  // Function to handle the AddSubmit action or EditSubmit action based on active tab and key
  const handleFormSubmit = (value, key) => {
    const handler = handlers[activeTab];
    if (key == null) {
      if (handler && handler.addSubmit) handler.addSubmit(value);
    } else {
      if (handler && handler.editSubmit) handler.editSubmit(value, key);
    }
  };

  // Function to handle the DeleteSubmit action based on active tab
  const handleDeleteSubmit = (key) => {
    const handler = handlers[activeTab];
    if (handler && handler.deleteSubmit) handler.deleteSubmit(key);
  };

  useEffect(() => {
    getUserList();
    getProductList();
    getMillCertList();
    getMechanicalMinMaxList();
    getWeightMinMaxList();
    getChemicalMinMaxList();
    getBarMarkList();
    getCustomerList();
  }, []);

  const managementContent = {
    user_management: (
      <UserManagementTable
        tableData={userList}
        handleEdit={handleEditOpen}
        handleDelete={handleDeleteSubmit}
        deleteLoading={userDeleteLoading}
      />
    ),
    product_management: (
      <ProductManagementTable
        tableData={productList}
        handleEdit={handleEditOpen}
      />
    ),
    mill_cert_management: (
      <MillCertManagementTable
        tableData={millCertList}
        handleEdit={handleEditOpen}
      />
    ),
    mechanical_management: (
      <MechanicalManagementTable
        tableData={mechanicalMinMaxList}
        handleEdit={handleEditOpen}
      />
    ),
    weight_management: (
      <WeightManagementTable
        tableData={weightMinMaxList}
        handleEdit={handleEditOpen}
      />
    ),
    chemical_management: (
      <ChemicalManagementTable
        tableData={chemicalMinMaxList}
        handleEdit={handleEditOpen}
      />
    ),
    customer_management: (
      <CustomerManagementTable
        tableData={customerList}
        handleEdit={handleEditOpen}
        handleDelete={handleDeleteSubmit}
        deleteLoading={customerDeleteLoading}
      />
    ),
    bar_mark_management: (
      <BarMarkManagementTable
        tableData={barMarkList}
        handleEdit={handleEditOpen}
        handleDelete={handleDeleteSubmit}
        deleteLoading={barMarkDeleteLoading}
      />
    ),
  };

  // Define the form components mapping
  const managementForm = {
    user_management: UserManagementForm,
    mechanical_management: MechanicalManagementForm,
    chemical_management: ChemicalManagementForm,
    mill_cert_management: MillCertManagementForm,
    weight_management: WeightManagementForm,
    customer_management: CustomerManagementForm,
    bar_mark_management: BarMarkManagementForm,
  };
  const ActiveForm = managementForm[activeTab];

  return (
    <>
      <Card
        className="context-card"
        tabList={MANAGEMENT_TAB}
        activeTabKey={activeTab}
        onTabChange={onTabChange}
        tabBarExtraContent={
          <Flex gap="small">
            {ACTIVE_ADD_BUTTON.includes(activeTab) && (
              <Button
                icon={<PlusOutlined style={{ color: "white" }} />}
                onClick={handleAddOpen}
                style={{ backgroundColor: "#0000A3" }}
              />
            )}
          </Flex>
        }
        loading={
          userListLoading ||
          productListLoading ||
          mechanicalMinMaxLoading ||
          chemicalMinMaxLoading ||
          millCertLoading ||
          weightMinMaxLoading ||
          barMarkLoading ||
          customerLoading
        }
      >
        {managementContent[activeTab]}
      </Card>

      {ActiveForm && (
        <ActiveForm
          modalOpen={activeModal}
          setModalOpen={setActiveModal}
          editData={activeEditData}
          setEditData={setActiveEditData}
          onSubmit={handleFormSubmit}
          loading={formLoading}
        />
      )}
    </>
  );
}
