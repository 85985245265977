import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useUpdateDeliverOrder() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [updateLoading, setUpdateLoading] = useState(false);

  const updateDeliverOrder = useCallback(
    (data, key, handleDeliveryOrderList, setMillCertModal) => {
      return new Promise((resolve, reject) => {
        setUpdateLoading(true);
        fetchWrapper({
          endpoint_url: "delivery_order/updateDeliveryOrder",
          params: {
            data: data,
            key: key,
          },
          setLoading: setUpdateLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              setMillCertModal(false);
              handleDeliveryOrderList();
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to update machenical properties",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Update Machenical Properties), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateDeliverOrder, updateLoading };
}

export default useUpdateDeliverOrder;
