import { useCallback, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useUpdateMechanicalMinMax() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const updateMechanicalMinMax = useCallback(
    (
      data,
      key,
      getMechanicalMinMaxList,
      setFormLoading,
      setMechanicalModal
    ) => {
      return new Promise((resolve, reject) => {
        setFormLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/updateMechanicalMinMax",
          params: {
            data: data,
            key: key,
          },
          setLoading: setFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              setMechanicalModal(false);
              getMechanicalMinMaxList();
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to update mechanical min max",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Update Mechanical Min Max), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateMechanicalMinMax };
}

export default useUpdateMechanicalMinMax;
