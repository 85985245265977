import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useUpdateRebarInfo() {
  const [fetchWrapper] = useFetch();
  const [updateLoading, setUpdateLoading] = useState(false);
  const { messageApi } = useContext(IndexContext);

  const updateRebarInfo = useCallback(
    (rebar_key, userCount, navigateBack, coordinate) => {
      setUpdateLoading(true);
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "rebar_verification/updateRebarInfo",
          params: {
            rebar_id: rebar_key,
            user_count: userCount,
            coordinate: coordinate,
          },
          setLoading: setUpdateLoading,
          onSuccess: (fetch_ouput) => {
            if (fetch_ouput.status) {
              messageApi.open({
                type: "success",
                content: fetch_ouput.message,
              });
              navigateBack();
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_ouput.error,
              });
            }
            resolve(fetch_ouput);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to update rebar info",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content: "Failed to Respond (Update Rebar), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateRebarInfo, updateLoading };
}

export default useUpdateRebarInfo;
