import { Button, Col, Image, Space } from "antd";
import { useState } from "react";
export default function RebarVerificationChooseImage({
  imageSrc,
  setSelectImage,
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleSelectImage = (index, src) => {
    setSelectedIndex(index);
    setSelectImage(src);
  };

  return (
    <>
      {imageSrc.map((src, index) => (
        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
          <Space.Compact direction="vertical">
            <Button
              type={selectedIndex == index ? "primary" : ""}
              style={{
                width: "100%",
                marginLeft: "0px",
              }}
              onClick={() => {
                handleSelectImage(index, src);
              }}
              aria-label={`Select AI Image ${index + 1}`}
              children={`AI Image ${index + 1}`}
            />
            <Image src={src} />
          </Space.Compact>
        </Col>
      ))}
    </>
  );
}
