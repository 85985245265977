import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetStockData() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [stockTableData, setStockTableData] = useState([]);
  const [stockColumn, setStockColumn] = useState([]);
  const [stockChartData, setStockChartData] = useState([]);
  const [stockDownload, setStockDownload] = useState([]);

  const getStockData = useCallback(
    (factory, diameter, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistics/getStockData",
        params: {
          factory: factory,
          diameter: diameter,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setStockTableData(fetch_output.table_data);
            setStockColumn(fetch_output.table_column);
            setStockChartData(fetch_output.chart_data);
            setStockDownload(fetch_output.download_data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Netword Error. Failed to get inventory table",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Inventory Table), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getStockData,
    stockTableData,
    stockColumn,
    stockChartData,
    stockDownload,
  };
}

export default useGetStockData;
