import { useCallback, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useSnapRebarImageTest() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const snapRebarImageTest = useCallback(
    (url, checkRebarTestStatus, setImageUploading) => {
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "testing/snapRebarImageTest",
          params: {
            image_url: url,
            tag_key: 0,
            tag_id: "BR-10-B-240918-1438",
          },
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              checkRebarTestStatus(fetch_output.key, setImageUploading);
            } else {
              setImageUploading({ progress: 100, state: false, res: false });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            setImageUploading({ progress: 100, state: false, res: false });
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to upload Rebar Test image",
            });
          },
          onResponseError: () => {
            setImageUploading({ progress: 100, state: false, res: false });
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Upload Rebar Test Image), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { snapRebarImageTest };
}

export default useSnapRebarImageTest;
