import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetMechanicalMinMaxList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [mechanicalMinMaxLoading, setMechanicalMinMaxLoading] = useState(false);
  const [mechanicalMinMaxList, setMechanicalMinMaxList] = useState([]);

  const getMechanicalMinMaxList = useCallback(() => {
    setMechanicalMinMaxLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getMechanicalMinMaxList",
      setLoading: setMechanicalMinMaxLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setMechanicalMinMaxList(fetch_output.data);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get mechanical min max list",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content:
            "Failed to Respond (Mechanical Min Max List), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);
  return {
    getMechanicalMinMaxList,
    mechanicalMinMaxList,
    mechanicalMinMaxLoading,
  };
}

export default useGetMechanicalMinMaxList;
