import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetBundleListInfo() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [bundleInfoLoading, setBundleInfoLoading] = useState(false);
  const [bundleListInfo, setBundleListInfo] = useState([]);

  const getBundleListInfo = useCallback(
    (startDate, endDate) => {
      setBundleInfoLoading(true);
      fetchWrapper({
        endpoint_url: "bundle_list/getBundleListInfo",
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        setLoading: setBundleInfoLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setBundleListInfo(fetch_output.data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get bundle list info",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Bundle List Info), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getBundleListInfo, bundleListInfo, bundleInfoLoading };
}

export default useGetBundleListInfo;
