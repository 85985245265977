import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetBundleListSummary() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [bundleSummaryLoading, setBundleSummaryLoading] = useState(false);
  const [bundleSummary, setBundleSummary] = useState({
    bundles_complete: 0,
    rebar_count_progress: {
      verified: 0,
      ai_counted: 100,
    },
    breakdown_verified: {
      adjusted: 100,
      non_adjust: 100,
    },
    stage: [
      {
        ai_process: 0,
        verified: 0,
        pending: 0,
        stage: "Initial",
      },
      {
        ai_process: 0,
        verified: 0,
        pending: 0,
        stage: "Rework",
      },
      {
        ai_process: 0,
        verified: 0,
        pending: 0,
        stage: "Load",
      },
    ],
  });

  const getBundleListSummary = useCallback(
    (startDate, endDate) => {
      setBundleSummaryLoading(true);
      fetchWrapper({
        endpoint_url: "bundle_list/getBundleListSummary",
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        setLoading: setBundleSummaryLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setBundleSummary(fetch_output.data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get bundle list summary",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Bundle List Summary), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getBundleListSummary, bundleSummary, bundleSummaryLoading };
}

export default useGetBundleListSummary;
