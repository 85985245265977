import { Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import React from "react";

export function generateRoutes(
  ignoreRoutes = [],
  disabledUserTypesConfig = {}
) {
  // Ensure `require.context` is used
  const pageModules = require.context("../pages", true, /\.jsx?$/);

  return pageModules.keys().reduce((routes, filePath) => {
    const component = pageModules(filePath).default;
    const segments = filePath
      .replace(/^\.\//, "") // Remove './' at the beginning
      .replace(/\.(jsx?|tsx?)$/, "") // Remove file extension
      .split("/"); // Split into path segments

    const routePath = "/" + segments.slice(0, -1).join("/"); // Build path excluding file name
    const routeName = segments[segments.length - 1]; // Get the file name without extension

    // Skip ignored routes
    if (ignoreRoutes.includes(routeName)) return routes;

    routes.push({
      path: routePath,
      name: routeName,
      component,
      disabledUserTypes: disabledUserTypesConfig[routeName] || [],
    });

    return routes;
  }, []);
}

export const renderRoutes = (routes) => {
  return routes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      element={
        <ProtectedRoute
          disabledUserTypes={route.disabledUserTypes}
          children={React.createElement(route.component)}
        />
      }
    />
  ));
};
