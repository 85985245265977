import { useCallback, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useCheckBundleListVerfiyStatus() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const checkBundleListVerifyStatus = useCallback(
    (getBundleListInfo, getBundleListSummary, startDate, endDate) => {
      fetchWrapper({
        endpoint_url: "bundle_list/checkBundleListVerifyStatus",
        method: "GET",
        set_content_type_header: false,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            getBundleListInfo(startDate, endDate);
            getBundleListSummary(startDate, endDate);
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to check bundle list status",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Check Bundle List Status), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { checkBundleListVerifyStatus };
}

export default useCheckBundleListVerfiyStatus;
