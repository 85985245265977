import { useContext, useEffect, useState } from "react";
import { IndexContext } from "../../../context/IndexContext";
import StatisticWIPChart from "./StatisticWIPChart";
import StatisticWIPTable from "./StatisticWIPTable";

export default function StatisticWIPPage({
  chartData,
  tableData,
  tableColumn,
}) {
  const { isLightTheme } = useContext(IndexContext);
  const [chartTextColor, setChartTextColor] = useState(
    isLightTheme ? "#000000" : "#ffffff"
  );

  useEffect(() => {
    setChartTextColor(isLightTheme ? "#000000" : "#ffffff");
  }, [isLightTheme]);

  return (
    <>
      <StatisticWIPChart
        chartData={chartData}
        chartTextColor={chartTextColor}
      />
      <StatisticWIPTable tableData={tableData} tableColumn={tableColumn} />
    </>
  );
}
