import { theme } from "antd";
import { USER_THEME, theme_config } from "../configs/theme_config";
import { useState } from "react";

// to load theme switcher
export default function useThemeSwitcher() {
  const [isLightTheme, setIsLightTheme] = useState(USER_THEME);
  const [appTheme, setAppTheme] = useState(theme.getDesignToken(theme_config)); // set default from theme_config

  function updateTheme(is_light_theme) {
    // update local storage
    localStorage.setItem("pixevision-app-is-light-theme", is_light_theme);

    // update state
    setIsLightTheme(is_light_theme);

    // update appTheme state
    setAppTheme({
      ...appTheme,
      algorithm: is_light_theme ? theme.defaultAlgorithm : theme.darkAlgorithm,
    });
  }

  return [appTheme, updateTheme, isLightTheme];
}
