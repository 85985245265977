import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetDOProgressList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [isLoading, setIsLoading] = useState(false);
  const [doProgressList, setDOProgressList] = useState([]);

  const getDOProgressList = useCallback(
    (startDateSelection, endDateSelection) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "do_progress/getDOProgressList",
        params: {
          startDate: startDateSelection,
          endDate: endDateSelection,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setDOProgressList(fetch_output.data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content:
              "Network Error, Failed to get Delivery Order Progress List",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Get Delivery Order Progress List), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getDOProgressList, doProgressList, isLoading };
}

export default useGetDOProgressList;
