import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetAllChemicalComposition() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [chemicalComposition, setChemicalComposition] = useState([]);
  const [chemicalLoading, setChemicalLoading] = useState(false);

  const getAllChemicalComposition = useCallback(
    (startDateSelection, endDateSelection) => {
      setChemicalLoading(true);
      fetchWrapper({
        endpoint_url: "material_properties/getAllChemicalComposition",
        params: {
          startDate: startDateSelection,
          endDate: endDateSelection,
        },
        setLoading: setChemicalLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setChemicalComposition(fetch_output.data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get all chemical composition",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (All Chemical Composition), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );
  return { getAllChemicalComposition, chemicalComposition, chemicalLoading };
}

export default useGetAllChemicalComposition;
