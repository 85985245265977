import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetBarMarkList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [barMarkList, setBarMarkList] = useState([]);
  const [barMarkLoading, setBarMarkLoading] = useState(false);

  const getBarMarkList = useCallback(() => {
    setBarMarkLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getBarMarkList",
      setLoading: setBarMarkLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setBarMarkList(fetch_output.data);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get bar mark list",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content: "Failed to Respond (Bar Mark List), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  return { getBarMarkList, barMarkList, barMarkLoading };
}

export default useGetBarMarkList;
