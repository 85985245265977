import { Button, Col, Flex, Image, Row, Tag, Typography } from "antd";
import CustomModal from "../common/CustomModal";
import DataTable from "../common/DataTable";
import { useNavigate } from "react-router-dom";
import FunctionalButton from "../common/FunctionalButton";
import { useState } from "react";

export default function DOProgressPreviewModal({
  modalOpen,
  setModalOpen,
  summaryData,
  tableData,
  handleRemove,
  removeLoading,
}) {
  const [loadRowKey, setLoadRowKey] = useState(null);
  const navigate = useNavigate();

  const TAG_COLUMN = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 100,
    },
    {
      title: "Serial Number",
      dataIndex: "tag_id",
      key: "tag_id",
      width: 220,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
    },
    {
      title: "Rebar Qty (Expected Qty)",
      dataIndex: "rebar_qty",
      key: "rebar_qty",
      width: 220,
    },
    {
      title: "Last Update",
      dataIndex: "last_update",
      key: "last_update",
      width: 220,
    },
    {
      title: "Captured By",
      dataIndex: "last_update_operator",
      key: "last_update_operator",
      width: 180,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      width: 150,
      render: (_, record, index) => {
        return record.remark === "rework" ? (
          <Tag color="blue">Rework</Tag>
        ) : record.remark === "matched" ? (
          <Tag color="green">Matched</Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
      render: (_, record) => {
        return record.verify_status === true ? (
          <Button type="link" disabled>
            In Progress
          </Button>
        ) : (
          <Flex>
            <Button
              type="link"
              style={{
                color: record.status !== "Verified" ? "green" : "",
              }} // Change color to green if the status is not "Verified"
              onClick={() => navigateVerification(record.key)}
              children={record.status !== "Verified" ? "Verify" : "Review"}
            />
            {record.remove && (
              <FunctionalButton
                buttonClickFunction={() => {
                  handleRemove(record.key);
                  setLoadRowKey(record.key);
                }}
                show_confirmation={true}
                confirmation_message={{
                  title: "Confirmation",
                  content: "Sure to remove this bundle ?",
                }}
                children={"Remove"}
                type="link"
                danger={true}
                trigger_loading={loadRowKey == record.key && removeLoading}
              />
            )}
          </Flex>
        );
      },
    },
  ];

  // Function to navigate to Rebar Verification Page
  const navigateVerification = (rebar_key) => {
    navigate("/rebar_verification", { state: { value: rebar_key } });
  };

  return (
    <CustomModal
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={1600}
      title={`Delivery Order Progress - ${summaryData.delivery_order_id}`}
      content={
        <>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={6}>
              <h3>Customer</h3>
              <Typography.Text children={summaryData.customer} />
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
              <h3>Specification</h3>
              <Typography.Text children={summaryData.specification} />
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
              <h3>Description of Good</h3>
              <Typography.Text children={summaryData.description} />
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} />
            <Col xs={24} sm={12} md={12} lg={6}>
              <h3>Address</h3>
              {summaryData.address &&
                summaryData.address.split("\n").map((line, index) => (
                  <Typography.Text key={index}>
                    {line}
                    <br />
                  </Typography.Text>
                ))}
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
              <h3>Bar Mark</h3>
              {summaryData.bar_mark &&
                summaryData.bar_mark.map((src) => {
                  return <Image src={src} />;
                })}
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col>
              <DataTable table_column={TAG_COLUMN} table_data={tableData} />
            </Col>
          </Row>
        </>
      }
    />
  );
}
