import { Table } from "antd";
import { useState, useEffect, useLayoutEffect } from "react";
import { useContext } from "react";
import { IndexContext } from "../../context/IndexContext";

/**
 * A common table that cater for most needs.
 * Auto resizable, loading indicator, selectable pagination
 *
 * @export
 * @param {{ table_column: ColumnType[]; table_data: object[]; tableWrapperViewportHeight?: number; layoutRef: React.Ref; filterRef: React.Ref; }} param0
 * @param {*} param0.table_column
 * @param {*} param0.table_data
 * @param {number} [param0.tableWrapperViewportHeight=1]
 * @param {*} param0.layoutRef
 * @param {*} param0.filterRef
 * @returns
 */
export default function DataTable({
  table_column,
  table_data,
  selection_row,
  tableRowSelection = false,
  tableWrapperViewportHeight = 1,
  tableHeightOffset = 0,
  layoutRef,
  filterRef,
  custom_table_style = {},
  pagination_style = { margin: "10px" },
  size = "middle",
  temp_table_height,
  show_sorter_tooltip = true,
  show_size_changer = undefined,
  paginationClose = false,
  pageSize = 5,
}) {
  const { isLightTheme } = useContext(IndexContext);

  const default_table_style = {
    height: "100%",
    width: "100%",
    ...custom_table_style,
  };

  // for resizeable table
  const [tableHeight, setTableHeight] = useState();

  function resizeTable() {
    // default to 0. set height only if ref is set.
    let layout_height = 0;
    let height = 0;
    if (layoutRef && layoutRef.current) {
      layout_height = layoutRef.current.clientHeight;
    }
    if (filterRef && filterRef.current) {
      height = filterRef.current.clientHeight;
    }

    setTableHeight(
      (layout_height - 50) * tableWrapperViewportHeight -
        height -
        tableHeightOffset
    );
  }

  useLayoutEffect(() => {
    resizeTable();
    window.addEventListener("resize", resizeTable);
    // observe table resize
    // create for whichever ref that is not null. priority is based on layout
    let resize_observer = null;
    if (layoutRef && layoutRef.current) {
      resize_observer = new ResizeObserver(resizeTable);
      resize_observer.observe(layoutRef.current);
    } else if (filterRef && filterRef.current) {
      resize_observer = new ResizeObserver(resizeTable);
      resize_observer.observe(filterRef.current);
    }

    return () => {
      window.removeEventListener("resize", resizeTable);
    };
  }, [layoutRef, filterRef]);

  useEffect(() => {
    if (temp_table_height != null) {
      setTableHeight(temp_table_height);
    }
  }, []);

  const [selectionType, setSelectionType] = useState("checkbox");

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      selection_row(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <Table
      showHeader
      rowSelection={
        tableRowSelection
          ? {
              type: selectionType,
              ...rowSelection,
            }
          : false
      }
      style={default_table_style}
      scroll={{ scrollToFirstRowOnChange: true, x: 1300, y: tableHeight }}
      tableLayout="fixed"
      loading={table_data && table_column ? false : true}
      pagination={
        paginationClose
          ? false
          : {
              responsive: true,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: [3, 5, 10, 20, 50, 100],
              position: "bottomRight",
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }
      }
      columns={table_column}
      dataSource={table_data.map((item, index) => ({
        ...item,
        number: index + 1,
      }))}
      size={size}
      showSorterTooltip={show_sorter_tooltip}
    ></Table>
  );
}
