import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetCustomerList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [customerList, setCustomerList] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);

  const getCustomerList = useCallback(() => {
    setCustomerLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getCustomerList",
      setLoading: setCustomerLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setCustomerList(fetch_output.data);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get customer list",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content: "Failed to Respond (Customer List), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  return { getCustomerList, customerList, customerLoading };
}

export default useGetCustomerList;
