import { Button, Divider, Flex, Form, Row, Select } from "antd";
import CustomModal from "../../common/CustomModal";
import { useEffect, useState } from "react";
import CustomFormItems from "../../common/CustomFormItems";
import useGetDiameterTypeOption from "../../../functions/Options/getDiameterTypeOption";

const TEST_OPTIONS = [
  { label: "PASS", value: "PASS" },
  {
    label: "FAIL",
    value: "FAIL",
  },
];

const GRADE_OPTIONS = [
  { label: "B250B", value: "B250B" },
  { label: "B500B", value: "B500B" },
];

export default function MechanicalModalForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [mechanicalForm] = Form.useForm();
  const [mechanicalGrade, setMechanicalGrade] = useState(
    editData ? editData["grade"] : GRADE_OPTIONS[0].value
  );

  const { diameterOption } = useGetDiameterTypeOption();

  const handleFieldChange = () => {
    const yieldValue = mechanicalForm.getFieldValue("yield");
    const tensileValue = mechanicalForm.getFieldValue("tensile");

    if (yieldValue !== undefined && tensileValue !== undefined)
      mechanicalForm.setFieldValue(
        "ratio",
        (tensileValue / yieldValue).toFixed(2)
      );
  };

  const onFinish = (value) => {
    const formData = { ...value, grade: mechanicalGrade };

    if (editData) {
      onSubmit(formData, editData["key"]);
    } else onSubmit(formData, null);
  };

  const handleClearForm = () => mechanicalForm.resetFields();

  useEffect(() => {
    if (modalOpen == false) {
      mechanicalForm.resetFields();
      setEditData(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (editData) mechanicalForm.setFieldsValue(editData);
  }, [editData]);

  const HEAT_NUMBER_INFORMATION_FORM = [
    {
      label: "Heat Number",
      name: "heat_no",
      type: "input",
      required: true,
      quarter: true,
    },
    {
      label: "Product",
      name: "product",
      type: "select",
      options: diameterOption,
      required: true,
      quarter: true,
    },
    {
      label: "Size (mm)",
      name: "size",
      type: "input",
      required: true,
      quarter: true,
    },
    {
      label: "Length (mm)",
      name: "length",
      type: "input",
      required: true,
      quarter: true,
    },
    {
      label: "Rebar Production Date",
      name: "rebar_production_date",
      type: "datetime-select",
      required: true,
    },
    {
      label: "Bend Test",
      name: "bend_test",
      type: "select",
      quarter: true,
      required: true,
      options: TEST_OPTIONS,
    },
    {
      label: "Rebend Test",
      name: "rebend_test",
      type: "select",
      quarter: true,
      required: true,
      options: TEST_OPTIONS,
    },
  ];

  const MECHANICAL_PROPERTIES_FORM = [
    {
      label: "Yield (YS)",
      name: "yield",
      type: "input-number",
      quarter: true,
      required: true,
    },
    {
      label: "Tensile (TS)",
      name: "tensile",
      type: "input-number",
      quarter: true,
      required: true,
    },
    {
      label: "Elongation (AGT)",
      name: "elongation",
      type: "input-number",
      quarter: true,
      required: true,
    },
    {
      label: "Ratio (TS/YS)",
      name: "ratio",
      type: "input-number",
      disabled: true,
      quarter: true,
      required: true,
    },
    {
      label: "Weight (kg/m)",
      name: "weight",
      type: "input-number",
      quarter: true,
      required: true,
    },
  ];

  return (
    <CustomModal
      title={
        <Flex align="center" gap={"large"} wrap>
          <span>
            {editData
              ? "Edit Mechanical Properties"
              : "Add Mechanical Properties"}
          </span>
        </Flex>
      }
      close_icon={true}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <>
          <Flex justify="flex-start" gap={"small"} align="center">
            <h3>Grade Select: </h3>
            <Select
              options={GRADE_OPTIONS}
              onChange={(value) => setMechanicalGrade(value)}
              value={mechanicalGrade}
              disabled={editData}
              style={{ width: "180px" }}
            />
          </Flex>
          <Form
            form={mechanicalForm}
            onFinish={onFinish}
            onFieldsChange={handleFieldChange}
          >
            <Divider
              orientation="left"
              orientationMargin="0"
              children={<strong>Heat Number & Information</strong>}
              style={{ borderColor: "#C0C0C0" }}
            />
            <Row gutter={[16, 16]}>
              <CustomFormItems form_properties={HEAT_NUMBER_INFORMATION_FORM} />
            </Row>
            <Divider
              orientation="left"
              orientationMargin="0"
              children={<strong>Mechanical Properties</strong>}
              style={{ borderColor: "#C0C0C0" }}
            />
            <Row gutter={[16, 16]}>
              <CustomFormItems form_properties={MECHANICAL_PROPERTIES_FORM} />
            </Row>

            <Flex gap="middle" justify="flex-end">
              <Form.Item>
                <Button
                  onClick={handleClearForm}
                  type="link"
                  children="Clear All"
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  children={editData ? "Update" : "Create"}
                  loading={loading}
                />
              </Form.Item>
            </Flex>
          </Form>
        </>
      }
    />
  );
}
