import { useCallback, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useUpdateRebarVerifyStatus() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const updateRebarVerifyStatus = useCallback(
    (rebar_key, navigateBack) => {
      fetchWrapper({
        endpoint_url: "rebar_verification/updateRebarVerifyStatus",
        params: {
          rebar_id: rebar_key,
        },
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            navigateBack();
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to update rebar status",
          });
          navigateBack();
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Update Rebar Verify Status), Please Contact Admin",
          });
          navigateBack();
        },
      });
    },
    [fetchWrapper]
  );

  return { updateRebarVerifyStatus };
}

export default useUpdateRebarVerifyStatus;
