import { useCallback, useContext, useState } from "react";
import { IndexContext } from "../../../context/IndexContext";
import useFetch from "../../../hooks/useFetch";

function useGetShiftBundles() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [shiftChart, setShiftChart] = useState([]);
  const [shiftSummary, setShiftSummary] = useState({});

  const getShiftBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getShiftBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setShiftChart(fetch_output.chart_data);
            setShiftSummary(fetch_output.summary_data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get shift bundles",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content: "Failed to Respond (Shift Bundles), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getShiftBundles, shiftChart, shiftSummary };
}

export default useGetShiftBundles;
