import { Card, Typography } from "antd";
import CustomModal from "../../../common/CustomModal";
import DataTable from "../../../common/DataTable";
import React from "react";

export default function ProductDetailsModalTable({
  tableData,
  productName,
  modalOpen,
  setModalOpen,
}) {
  const PRODUCT_DETAILS_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Grade Name",
      dataIndex: "grade_name",
      key: "grade_name",
      width: 120,
    },
    {
      title: "Grade Type",
      dataIndex: "grade_type",
      key: "grade_type",
      width: 120,
    },
    {
      title: "Mill Cert Name",
      dataIndex: "mill_cert_name",
      key: "mill_cert_name",
      width: 160,
    },
    {
      title: "Mill Cert Description",
      dataIndex: "mill_cert_description",
      key: "mill_cert_description",
      width: 200,
    },
    {
      title: "Mill Cert Specification",
      dataIndex: "mill_cert_specification",
      key: "mill_cert_specification",
      width: 200,
    },
    {
      title: "Bend Test",
      dataIndex: "bend_test",
      key: "bend_test",
      width: 180,
    },
    {
      title: "Rebend Test",
      dataIndex: "rebend_test",
      key: "rebend_test",
      width: 240,
    },
    {
      title: "Statement",
      dataIndex: "statement",
      key: "statement",
      width: 300,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      width: 300,
      render: (remark) => (
        <>
          {remark.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
  ];

  return (
    <CustomModal
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={1600}
      content={
        <Card
          title={`Product Details - ${productName}`}
          className="context-card"
          children={
            <DataTable
              table_column={PRODUCT_DETAILS_COLUMN}
              table_data={tableData}
            />
          }
        />
      }
    />
  );
}
