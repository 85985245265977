import useFetch from "../../hooks/useFetch";
import { useCallback, useContext, useEffect, useState } from "react";
import { IndexContext } from "../../context/IndexContext";

function useGetDeliverOrderList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [deliverOrderList, setDeliverOrderList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const getDeliverOrderList = useCallback(
    (startDateSelection, endDateSelection) => {
      setTableLoading(true);
      fetchWrapper({
        endpoint_url: "delivery_order/getDeliveryOrderList",
        params: {
          startDate: startDateSelection,
          endDate: endDateSelection,
        },
        setLoading: setTableLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setDeliverOrderList(fetch_output.data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get delivery order list",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Delivery Order List), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getDeliverOrderList, deliverOrderList, tableLoading };
}

export default useGetDeliverOrderList;
