import useFetch from "../../../hooks/useFetch";
import { useCallback, useContext } from "react";
import { IndexContext } from "../../../context/IndexContext";

function useUpdateMechanicalProperties() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const updateMechanicalProperties = useCallback(
    (
      mechanical_data,
      mechanical_key,
      handleMechanicalProperties,
      setMachenicalFormLoading,
      setMechanicalModal
    ) => {
      return new Promise((resolve, reject) => {
        setMachenicalFormLoading(true);
        fetchWrapper({
          endpoint_url: "material_properties/updateMechanicalProperties",
          params: {
            mechanical_data: mechanical_data,
            mechanical_key: mechanical_key,
          },
          setLoading: setMachenicalFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              setMechanicalModal(false);
              handleMechanicalProperties();
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to update machenical properties",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Update Machenical Properties), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { updateMechanicalProperties };
}

export default useUpdateMechanicalProperties;
