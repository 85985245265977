import { Card, Row, Col, Flex } from "antd";
import InventoryPieChart from "./inventory/InventoryPieChart";
import InventoryDonutChart from "./inventory/InventoryDonutChart";
import InventoryBarChart from "./inventory/InventoryBarChart";
import InventoryDonutSummary from "./inventory/InventoryDonutSummary";

export default function InventoryCard({
  chartTextColor,
  pieChartData,
  donutChartData,
  barChartData,
  loading,
}) {
  return (
    <Card
      title={<strong>Inventory</strong>}
      style={{ height: "100%" }}
      loading={loading}
    >
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={12} md={7}>
          <InventoryPieChart
            data={pieChartData}
            chartTextColor={chartTextColor}
          />
        </Col>
        <Col xs={24} sm={12} md={7}>
          <Flex
            vertical="vertical"
            align="center"
            justify="space-around"
            style={{ height: "100%" }}
          >
            <InventoryDonutChart data={donutChartData} />
            <InventoryDonutSummary data={donutChartData} />
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={10}>
          <InventoryBarChart
            data={barChartData}
            chartTextColor={chartTextColor}
          />
        </Col>
      </Row>
    </Card>
  );
}
