import { useCallback, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useInsertUser() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const insertUser = useCallback(
    (data, getUserList, setFormLoading, setUserModal) => {
      return new Promise((resolve, reject) => {
        setFormLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/insertUser",
          params: {
            data: data,
          },
          setLoading: setFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              setUserModal(false);
              getUserList();
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to add user",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content: "Failed to Respond (Add User), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { insertUser };
}

export default useInsertUser;
