export default function OperatorBundlesSummary({ summaryData }) {
  return (
    <>
      <div className="flexbox-container-col">
        <strong>Top Operator</strong>
        <strong>Lowest Operator</strong>
        <strong>Average</strong>
        <strong>Gap (Average-lowest)</strong>
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <strong>{":"}</strong>
        <strong>{":"}</strong>
        <strong>{":"}</strong>
        <strong>{":"}</strong>
      </div>
      <div className="flexbox-container-col">
        <span>{summaryData.top_operator}</span>
        <span>{summaryData.lowest_operator}</span>
        <span>{summaryData.average}</span>
        <span>{summaryData.gap}</span>
      </div>
    </>
  );
}
