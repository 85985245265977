import { Button, Card, Divider, Flex, Typography } from "antd";
import { useState, useEffect } from "react";
import "../../styles/bundle_list/BundleListPage.css";
import BundleListSummary from "../../components/bundle_list/BundleListSummary";
import BundleListTable from "../../components/bundle_list/BundleListTable";
import useGetBundleListSummary from "../../functions/BundleList/getBundleListSummary";
import useGetBundleListInfo from "../../functions/BundleList/getBundleListInfo";
import useCheckBundleListVerfiyStatus from "../../functions/BundleList/checkBundleListVerifyStatus";
import CustomRangePicker from "../../components/common/CustomRangePicker";
import dayjs from "dayjs";
import BundleListSearchForm from "../../components/bundle_list/BundleListSearchForm";

const { Text } = Typography;

export default function BundleListPage() {
  // To open the Advanced Search Form Modal
  const [searchModal, setSearchModal] = useState(false);
  // For handle data start date
  const [startDateSelection, setStartDateSelection] = useState(
    dayjs().subtract(6, "day").startOf("day")
  );
  // For handle data end date
  const [endDateSelection, setEndDateSelection] = useState(
    dayjs().endOf("day")
  );
  // For handle loading disabled
  const [loadingDisabled, setLoadingDisabled] = useState(false);

  // Api Function to Get the Bundle List Summary
  const { getBundleListSummary, bundleSummary, bundleSummaryLoading } =
    useGetBundleListSummary();
  // Api Function to Get the Bundle List Info
  const { getBundleListInfo, bundleListInfo, bundleInfoLoading } =
    useGetBundleListInfo();
  // Api Function to Check the Bundle List Verify Status
  const { checkBundleListVerifyStatus } = useCheckBundleListVerfiyStatus();

  // To filter Bundle List Table Data
  const [filterBundleListInfo, setFilterBundleListInfo] =
    useState(bundleListInfo);

  // To reset the Filter Bundle List Data to Original Bundle List Data
  const resetFilter = () => {
    setFilterBundleListInfo(bundleListInfo);
  };

  // To search new data when user change the date filter
  const handleDateFilter = () => {
    checkDataStatus(startDateSelection, endDateSelection);
  };

  // For call the api function
  const checkDataStatus = (startDate, endDate) => {
    checkBundleListVerifyStatus(
      getBundleListInfo,
      getBundleListSummary,
      startDate,
      endDate
    );
  };

  // To get the data
  useEffect(() => {
    setFilterBundleListInfo(bundleListInfo);
  }, [bundleListInfo]);

  // To call the function every 3 minutes and get the Bundle List data
  useEffect(() => {
    checkDataStatus(startDateSelection, endDateSelection);

    const interval = setInterval(() => {
      checkDataStatus(startDateSelection, endDateSelection);
    }, 6000000);

    return () => clearInterval(interval);
  }, []);

  // To check is the api still loading or not
  useEffect(() => {
    setLoadingDisabled(bundleInfoLoading || bundleSummaryLoading);
  }, [bundleInfoLoading, bundleSummaryLoading]);

  return (
    <>
      <Card
        className="context-card"
        title={
          <Flex justify="space-between" align="center" wrap="wrap" gap="small">
            <Text>Bundle List Table</Text>
            <Flex gap="small" wrap="wrap">
              <CustomRangePicker
                startDateSelection={startDateSelection}
                setStartDateSelection={setStartDateSelection}
                endDateSelection={endDateSelection}
                setEndDateSelection={setEndDateSelection}
                disabled={loadingDisabled}
                search={true}
                showTime={true}
                onFilterClick={handleDateFilter}
              />

              <Button
                onClick={() => {
                  resetFilter();
                }}
                disabled={loadingDisabled}
              >
                Reset
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  setSearchModal(true);
                }}
                disabled={loadingDisabled}
              >
                Advanced Search
              </Button>
            </Flex>
          </Flex>
        }
        loading={loadingDisabled}
      >
        <BundleListSummary data={bundleSummary} />
        <Divider />
        <BundleListTable tableData={filterBundleListInfo} />
      </Card>
      <BundleListSearchForm
        modalOpen={searchModal}
        setModalOpen={setSearchModal}
        tableData={bundleListInfo}
        setFilterTable={setFilterBundleListInfo}
      />
    </>
  );
}
