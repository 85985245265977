import { Button, Card } from "antd";
import CustomModal from "../common/CustomModal";
import DataTable from "../common/DataTable";
import { DownloadOutlined } from "@ant-design/icons";
import useDownloadFile from "../../functions/DownloadFile/downloadFile";

export default function MillCertFileTable({
  modalOpen,
  setModalOpen,
  tableData,
}) {
  const { downloadFile } = useDownloadFile();

  const MILL_CERT_FILE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 40,
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      width: 160,
    },
    {
      title: "Create By",
      dataIndex: "create_by",
      key: "create_by",
      width: 80,
    },
    {
      title: "Create Date Time",
      dataIndex: "create_date_time",
      key: "create_date_time",
      width: 120,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_, record) => {
        return (
          <Button
            icon={<DownloadOutlined />}
            onClick={() => downloadFile(record.folder_path, record.file_name)}
          />
        );
      },
    },
  ];

  return (
    <CustomModal
      visible={modalOpen}
      width={1600}
      onCancel={() => setModalOpen(false)}
      content={
        <Card
          title={"Mill Cert File Table"}
          className="context-card"
          children={
            <DataTable
              table_column={MILL_CERT_FILE_COLUMN}
              table_data={tableData}
            />
          }
        />
      }
    />
  );
}
