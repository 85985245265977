import { render } from "@antv/g2";
import { Flex } from "antd";
import FunctionalButton from "../../common/FunctionalButton";
import { EditOutlined } from "@ant-design/icons";
import DataTable from "../../common/DataTable";

export default function WeightManagementTable({ tableData, handleEdit }) {
  const WEIGHT_MIN_MAX_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 140,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 120,
    },
    {
      title: "Minimum",
      dataIndex: "minimum",
      key: "minimum",
      width: 140,
    },
    {
      title: "Maximum",
      dataIndex: "maximum",
      key: "maximum",
      width: 140,
    },
    {
      title: "Last Updated By",
      dataIndex: "last_update_by",
      key: "last_update_by",
      width: 140,
    },
    {
      title: "Last Update Date Time",
      dataIndex: "last_update_date_time",
      key: "last_update_date_time",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 160,
      render: (_, record) => {
        return (
          <Flex
            gap="small"
            children={
              <FunctionalButton
                buttonClickFunction={() => handleEdit(record)}
                type=""
                width="40px"
                icon={<EditOutlined />}
              />
            }
          />
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={WEIGHT_MIN_MAX_COLUMN}
      table_data={tableData}
      pageSize={10}
    />
  );
}
