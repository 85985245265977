import { useContext } from "react";
import { IndexContext } from "../../../context/IndexContext";
import { Pie } from "@ant-design/plots";
import { Empty, Flex } from "antd";

export default function InventoryDonutChart({ data }) {
  const { isLightTheme } = useContext(IndexContext);

  const configData = [
    {
      type: "Stuck",
      value: data.stuck,
    },
    {
      type: "Success",
      value: data.success,
    },
  ];

  const total = data.success + data.stuck;
  const stuckPercentage = ((data.stuck / total) * 100).toFixed(0);

  const config = {
    data: configData,
    angleField: "value",
    colorField: "type",
    style: {
      inset: 1,
      radiust: 5,
      align: "center",
    },
    tooltip: {
      title: "type",
    },
    radius: 0.8,
    innerRadius: 0.6,
    legend: false,
    scale: {
      color: {
        palette: ["#1890FF", "#D9D9D9"],
      },
    },
    annotations: [
      {
        type: "text",
        style: {
          text: `${stuckPercentage == "NaN" ? 0 : stuckPercentage}%`,
          x: "50%",
          y: "50%",
          textAlign: "center",
          fontStyle: "bold",
          fontSize: 16,
          fill: isLightTheme ? "#000000" : "#ffffff",
        },
        tooltip: false,
      },
    ],
  };

  return (
    <div style={{ height: "18vh" }}>
      {data ? (
        <Pie {...config} />
      ) : (
        <Flex align="center" justify="center" style={{ height: "100%" }}>
          <Empty />
        </Flex>
      )}
    </div>
  );
}
