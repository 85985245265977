import { useFetcher } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useCallback, useContext, useState } from "react";
import { IndexContext } from "../../context/IndexContext";

function useGetRebarOverallAccuracy() {
  const [fetchWrapper] = useFetch();
  const [accuracyLoading, setAccuracyLoading] = useState(false);
  const [overallAccuracy, setOverallAccuracy] = useState("-");
  const { messageApi } = useContext(IndexContext);

  const getRebarOverallAccuracy = useCallback(
    (navigateBack) => {
      fetchWrapper({
        endpoint_url: "rebar_verification/getRebarOverallAccuracy",
        setLoading: setAccuracyLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setOverallAccuracy(fetch_output.accuracy);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
            navigateBack();
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get rebar overall accuracy",
          });
          navigateBack();
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content: "Failed to Respond (Rebar Info), Please Contact Admin",
          });
          navigateBack();
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getRebarOverallAccuracy,
    overallAccuracy,
    accuracyLoading,
  };
}

export default useGetRebarOverallAccuracy;
