import { useCallback, useState, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useCheckRebarTestStatus() {
  const [fetchWrapper] = useFetch();
  const [rebarCount, setRebarCount] = useState(null);
  const [aiImageSrc, setAiImageSrc] = useState([]);
  const [resultType, setResultType] = useState(null);
  const { messageApi } = useContext(IndexContext);

  const checkRebarTestStatus = useCallback(
    (key, setIsLoading) => {
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "testing/checkRebarTestStatus",
          params: {
            id: key,
          },
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              if (
                fetch_output.ai_count !== null &&
                fetch_output.ai_image_src != null
              ) {
                setRebarCount(fetch_output.ai_count);
                setAiImageSrc(fetch_output.ai_image_src);
                setIsLoading({ progress: 100, state: false, res: true });
                setResultType(fetch_output.result_type);
              } else {
                setTimeout(() => {
                  checkRebarTestStatus(key, setIsLoading);
                }, 5000);
              }
            } else {
              setResultType(fetch_output.result_type);
              setIsLoading({ progress: 100, state: false, res: false });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            setResultType("network_error");
            setIsLoading({ progress: 100, state: false, res: false });
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to check Rebar Test Status",
            });
          },
          onResponseError: () => {
            setResultType("network_error");
            setIsLoading({ progress: 100, state: false, res: false });
            messageApi.open({
              type: "error",
              content:
                "Failed to Response (Check Rebar Test Status), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return {
    checkRebarTestStatus,
    rebarCount,
    setRebarCount,
    resultType,
    aiImageSrc,
  };
}

export default useCheckRebarTestStatus;
