import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useRemoveCustomer() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [customerDeleteLoading, setCustomerDeleteLoading] = useState(false);

  const removeCustomer = useCallback(
    (key, getCustomerList) => {
      return new Promise((resolve, reject) => {
        setCustomerDeleteLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/removeCustomer",
          params: { key: key },
          setLoading: setCustomerDeleteLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              getCustomerList();
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to insert customer",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Insert Customer), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { removeCustomer, customerDeleteLoading };
}

export default useRemoveCustomer;
