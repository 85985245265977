import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetChemicalMinMaxList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [chemicalMinMaxLoading, setChemicalMinMaxLoading] = useState(false);
  const [chemicalMinMaxList, setChemicalMinMaxList] = useState([]);

  const getChemicalMinMaxList = useCallback(() => {
    setChemicalMinMaxLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getChemicalMinMaxList",
      setLoading: setChemicalMinMaxLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setChemicalMinMaxList(fetch_output.data);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get chemical min max list",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content:
            "Failed to Respond (Chemical Min Max List), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);
  return { getChemicalMinMaxList, chemicalMinMaxList, chemicalMinMaxLoading };
}

export default useGetChemicalMinMaxList;
