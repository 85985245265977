import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetTraceabilityImage() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [imageLoading, setImageLoading] = useState(false);

  const getTraceabilityImage = useCallback(
    (files) => {
      return new Promise((resolve, reject) => {
        setImageLoading(true);
        fetchWrapper({
          endpoint_url: "traceability/getTraceabilityImage",
          params: {
            files: files,
          },
          setLoading: setImageLoading,
          onSuccess: (fetch_output) => {
            if (!fetch_output.status) {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }

            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to get traceability data",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Get Tag ID Traceability), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return {
    getTraceabilityImage,
    imageLoading,
  };
}

export default useGetTraceabilityImage;
