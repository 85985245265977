import DataTable from "../../common/DataTable";

export default function DeliveryTable({ tableColumn, tableData }) {
  return (
    <DataTable
      table_column={tableColumn}
      table_data={tableData}
      paginationClose={true}
    />
  );
}
