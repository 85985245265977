import { useCallback, useContext, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetCustomerOption() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [customerOption, setCustomerOption] = useState([]);
  const [customerSelect, setCustomerSelect] = useState(null);

  const getCustomerOption = useCallback(() => {
    fetchWrapper({
      endpoint_url: "options/getCustomerOption",
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setCustomerOption(fetch_output.options);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get customer option",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content: "Failed to Respond (Customer Option), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  useEffect(() => {
    getCustomerOption();
  }, []);

  return { customerOption };
}

export default useGetCustomerOption;
