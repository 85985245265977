import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetOperatorData() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [operatorTableData, setOperatorTableData] = useState([]);
  const [operatorColumn, setOperatorColumn] = useState([]);
  const [operatorChartData, setOperatorChartData] = useState([]);
  const [operatorDownload, setOperatorDownload] = useState([]);

  const getOperatorData = useCallback(
    (factory, diameter, operator, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistics/getOperatorData",
        params: {
          factory: factory,
          diameter: diameter,
          operator: operator,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setOperatorTableData(fetch_output.table_data);
            setOperatorColumn(fetch_output.table_column);
            setOperatorChartData(fetch_output.chart_data);
            setOperatorDownload(fetch_output.download_data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Netword Error. Failed to get operator table",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content: "Failed to Respond (Operator Table), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getOperatorData,
    operatorTableData,
    operatorColumn,
    operatorChartData,
    operatorDownload,
  };
}

export default useGetOperatorData;
