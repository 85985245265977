import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";
import useDownloadFile from "../DownloadFile/downloadFile";

function useDownloadMillCert() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { downloadFile } = useDownloadFile();

  const downloadMillCert = useCallback(
    (data, handleDeliveryOrderList) => {
      setDownloadLoading(true);
      fetchWrapper({
        endpoint_url: "delivery_order/downloadMillCert",
        params: {
          delivery_order_data: data,
        },
        setLoading: setDownloadLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            for (let i = 0; i < fetch_output.data.length; i++) {
              downloadFile(
                fetch_output.data[i].folder_path,
                fetch_output.data[i].file_name
              );
            }
            handleDeliveryOrderList();
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to download mill cert file",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Download Mill Cert File), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { downloadMillCert, downloadLoading };
}

export default useDownloadMillCert;
