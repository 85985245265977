import { useCallback, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useDownloadFile() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const downloadFile = useCallback(
    (folderPath, fileName) => {
      fetchWrapper({
        endpoint_url: "download_file/downloadFile",
        params: {
          folder_path: folderPath,
          file_name: fileName,
        },
        json_output: false,
        onSuccess: (response) => {
          if (response.headers.get("content-type") === "application/json") {
            response.json().then((data) => {
              if (data.error) {
                messageApi.open({
                  type: "warning",
                  content: data.error,
                });
              }
            });
          } else {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const downloadLink = document.createElement("a");
              downloadLink.href = url;
              downloadLink.setAttribute("download", fileName);
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to download file",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content: "Failed to Respond (Download File), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );
  return { downloadFile };
}

export default useDownloadFile;
