import { EyeOutlined } from "@ant-design/icons";
import { Button, Flex, Image, Modal, Result, Typography } from "antd";
import { useState } from "react";
const { Text } = Typography;

// For the Camera Result Template
const resultTemplate = {
  ai_process_success: {
    status: "success",
    title: "Okay!",
    subTitle: "Ready for next bundle?",
    buttonText: "Verify Rebar Bundle",
    retake: false,
  },
  ai_process_fail: {
    status: "error",
    title: "No rebar is detected!",
    subTitle: "AI fails to detect rebars. Please retake the image!",
    buttonText: "Take Photo Again",
    retake: false,
  },
  ai_count_more_than: {
    status: "warning",
    title: "Extra Rebars!",
    subTitle: "Rebar count not tally. Pending verification.",
    buttonText: "Verify Rebar Bundle",
    retake: true,
  },
  ai_count_less_than: {
    status: "warning",
    title: "Insufficient Rebars!",
    subTitle: "Rebar count not tally. Pending verification.",
    buttonText: "Verify Rebar Bundle",
    retake: true,
  },
  image_blur: {
    status: "error",
    title: "Blurry Image Detected!",
    subTitle: "The image could be blurry. Please retake the image!",
    buttonText: "Snap Image Again",
    retake: false,
  },
  selfie_success: {
    status: "success",
    title: "Success",
    subTitle: "You are good to go!",
    buttonText: "Let's Go!",
    retake: false,
  },
  selfie_fail: {
    status: "error",
    title: "No face is detected!",
    subTitle: "Your selfie image could be blurry. Please retake selfie!",
    buttonText: "Retake Selfie",
    retake: false,
  },
  network_error: {
    status: "error",
    title: "Network Error",
    subTitle: "Your image could not be uploaded. Please retake image!",
    buttonText: "Retake Rebar Bundle",
    retake: false,
  },
  timeout_error: {
    status: "error",
    title: "Timeout Error",
    subTitle: "Timeout for AI process image. Please retake image!",
    buttonText: "Retake Rebar Bundle",
    retake: false,
  },
  upload_fail: {
    status: "error",
    title: "Upload Image Error",
    subTitle: "Failed to upload rebar image, Please retake image!",
    buttonText: "Retake Rebar Bundle",
    retake: false,
  },
};

export default function CameraResultModal({
  resultModal,
  resultType,
  rebarCount,
  aiImageSrc,
  onResultConfirm,
  onResultRetake,
}) {
  // For handle the Image Result Visible
  const [imageVisible, setImageVisible] = useState(false);

  return (
    <Modal open={resultModal} footer={null} closable={false} centered>
      {resultType != null && (
        <Result
          status={resultTemplate[resultType].status}
          title={resultTemplate[resultType].title}
          subTitle={resultTemplate[resultType].subTitle}
          extra={
            <>
              <Flex vertical="vertical" gap="small" align="center">
                <Button type="primary" onClick={onResultConfirm}>
                  {resultTemplate[resultType].buttonText}
                </Button>
                {resultTemplate[resultType].retake && (
                  <Button onClick={onResultRetake}>Retake Rebar Bundle</Button>
                )}
                {rebarCount != null && aiImageSrc != null && (
                  <>
                    <Flex align="center" gap="middle" justify="center">
                      <Button
                        type="ghost"
                        icon={<EyeOutlined />}
                        onClick={() => setImageVisible(true)}
                      />

                      <Text>This Rebar Count is {rebarCount}</Text>
                    </Flex>
                    <Image.PreviewGroup
                      items={aiImageSrc}
                      preview={{
                        visible: imageVisible,
                        onVisibleChange: (value) => setImageVisible(value),
                      }}
                    />
                  </>
                )}
              </Flex>
            </>
          }
        />
      )}
    </Modal>
  );
}
