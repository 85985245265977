// import './css/Login.css';
import {
  Card,
  Image,
  Layout,
  Button,
  Form,
  Input,
  Space,
  theme,
  Select,
} from "antd";
import { useContext, useEffect } from "react";
import pixevisionLogo from "../../assets/companyLogo/pixevision-logo-long-png.png";
import pixevisionDarkLogo from "../../assets/companyLogo/pixevision-logo-long-dark.png";
import ParticleEffect from "../../components/login/ParticleEffect";
import { IndexContext } from "../../context/IndexContext";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import getBrowserFingerprint from "get-browser-fingerprint";
import useLogin from "../../functions/Login/login";
import useCheckUserLogin from "../../functions/Login/checkUserLogin";
import useGetStageOption from "../../functions/Options/getStageOption";

// const stageOption = [
//   { value: "After Bundling", label: "After Bundling Stage" },
//   { value: "Load", label: "Load Stage" },
//   { value: "Rework", label: "Rework Stage" },
// ];

export default function LoginPage() {
  // browser fingerprint
  const fingerprint = getBrowserFingerprint();

  // load index context
  const index_context = useContext(IndexContext);
  const { isLightTheme } = index_context;

  // load the design token
  const { token } = theme.useToken();

  // Api Function for User Login and Add the Login Record
  const { login, loginProcessing, setLoginProcessing } = useLogin();

  const { stageOption } = useGetStageOption();
  // Api Fcuntion to check User Login
  const { checkUserLogin } = useCheckUserLogin();

  // For handle Login
  const onFinish = (values) => login(values, fingerprint);

  // Fot handle Login Fail
  const onFinishFailed = (errorInfo) => setLoginProcessing(false);

  // For show the loading
  const showLoading = () => setLoginProcessing(true);

  // To run the function
  useEffect(() => {
    checkUserLogin();
  }, []);

  return (
    <div>
      <Layout style={{ height: "100vh" }}>
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ParticleEffect particle_color={token.colorText} />
          <Card
            style={{ width: "95%", maxWidth: "500px", padding: "1rem 1.5rem" }}
          >
            <Form
              name="basic"
              requiredMark={false}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              loading={loginProcessing}
              onSubmitCapture={showLoading}
            >
              <Space size={"large"} direction={"vertical"}>
                <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
                  <Image
                    src={isLightTheme ? pixevisionLogo : pixevisionDarkLogo}
                    preview={false}
                    width={"80%"}
                  ></Image>
                </div>
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Username"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Password"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  name="stage"
                  rules={[
                    { required: true, message: "Please choose the state" },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Stage Select"
                    options={stageOption}
                  />
                </Form.Item>
                <Form.Item style={{ paddingBottom: "0px" }}>
                  <Button
                    id="login-button"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={loginProcessing}
                    style={{ width: "100%" }}
                  >
                    Log In
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Card>
        </Layout.Content>
      </Layout>
    </div>
  );
}
