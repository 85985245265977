import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";
import useAddTagID from "./addTagID";

function useVerifyDeliveryOrder() {
  const [fetchtWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [navigateResultType, setNavigateResultType] = useState(null);
  const [tagId, setTagId] = useState(null);
  const [tagIdLoading, setTagIdLoading] = useState(false);

  const verifyDeliveryOrder = useCallback(
    (setNavigateModal) => {
      setTagIdLoading(true);
      fetchtWrapper({
        endpoint_url: "ai_rebar_count/verifyDeliveryOrder",
        onSuccess: (fetch_output) => {
          if (!fetch_output.status) {
            if ("result_type" in fetch_output) {
              setNavigateResultType(fetch_output.result_type);
              setNavigateModal(true);
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            setTagIdLoading(false);
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to verify delivery order",
          });
          setTagIdLoading(false);
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Verify Delivery Order), Please Contact Admin",
          });
          setTagIdLoading(false);
        },
      });
    },
    [fetchtWrapper]
  );

  return {
    verifyDeliveryOrder,
    tagId,
    setTagId,
    tagIdLoading,
    navigateResultType,
  };
}

export default useVerifyDeliveryOrder;
