import useFetch from "../../hooks/useFetch";
import { useCallback, useContext, useState } from "react";
import { IndexContext } from "../../context/IndexContext";
import { DashOutlined } from "@ant-design/icons";

function useInsertDeliverOrder() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [insertLoading, setInsertLoading] = useState(false);

  const insertDeliverOrder = useCallback(
    (data, handleDeliveryOrderList, setMillCertModal) => {
      return new Promise((resolve, reject) => {
        setInsertLoading(true);
        fetchWrapper({
          endpoint_url: "delivery_order/insertDeliveryOrder",
          params: {
            data: data,
          },
          setLoading: setInsertLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              setMillCertModal(false);
              handleDeliveryOrderList();
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to insert Deliver Order",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Insert Deliver Order), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { insertDeliverOrder, insertLoading };
}

export default useInsertDeliverOrder;
