import { useCallback, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useRemoveImage() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const removeImage = useCallback(
    (imageName, resetImage) => {
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "ai_rebar_count/removeImage",
          params: {
            filename: imageName,
          },
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              resetImage();
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to remove image",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Remove Rebar Image), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { removeImage };
}

export default useRemoveImage;
