import { Form } from "antd";
import CustomManagementForm from "../../common/CustomManagementForm";

const CHEMICAL_MANAGEMENT_FORM = [
  {
    label: "Chemical Composition",
    name: "chemical_name",
    type: "input",
    disabled: true,
  },
  {
    label: "Abbreviation",
    name: "chemical_short_name",
    type: "input",
    disabled: true,
  },
  {
    label: "Minimum",
    name: "minimum",
    type: "input-number",
  },
  {
    label: "Maximum",
    name: "maximum",
    type: "input-number",
  },
];

export default function ChemicalManagementForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [chemicalManagementForm] = Form.useForm();

  return (
    <CustomManagementForm
      form={chemicalManagementForm}
      formProperties={CHEMICAL_MANAGEMENT_FORM}
      modalTitle={
        editData
          ? `Edit Chemical Min Max - ${editData.chemical_name}`
          : "Add Chemical Min Max"
      }
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      editData={editData}
      setEditData={setEditData}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
}
