import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetWeightMinMaxList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [weightMinMaxList, setWeightMinMaxList] = useState([]);
  const [weightMinMaxLoading, setWeightMinMaxLoading] = useState(false);

  const getWeightMinMaxList = useCallback(() => {
    setWeightMinMaxLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getWeightMinMaxList",
      setLoading: setWeightMinMaxLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setWeightMinMaxList(fetch_output.data);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get weight min max list",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content:
            "Failed to Respond (Weight Min Max List), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  return {
    getWeightMinMaxList,
    weightMinMaxList,
    weightMinMaxLoading,
  };
}

export default useGetWeightMinMaxList;
