import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Image } from "antd";
import { useState } from "react";

export default function RebarVerificationImage({ imageSrc }) {
  // To handle Image Index that show
  const [imageIndex, setImageIndex] = useState(0);
  // Function to show Previous Image
  const handlePrev = () =>
    setImageIndex((index) => (index === 0 ? imageSrc.length - 1 : index - 1));
  // Function to show Next Image
  const handleNext = () =>
    setImageIndex((index) => (index === imageSrc.length - 1 ? 0 : index + 1));

  return (
    <>
      <Button
        type="ghost"
        size="large"
        icon={<LeftOutlined />}
        onClick={handlePrev}
      />
      <Image.PreviewGroup items={imageSrc}>
        <Image src={imageSrc[imageIndex]} className="bundle-image" />
      </Image.PreviewGroup>
      <Button
        type="ghost"
        size="large"
        icon={<RightOutlined />}
        onClick={handleNext}
      />
    </>
  );
}
