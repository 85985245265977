import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetTagIDTraceability() {
  const [fetchtWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [traceabilityTable, setTraceabilityTable] = useState([]);
  const [traceabilityLoading, setTraceabilityLoading] = useState(false);
  const [traceabilityDownload, setTraceabilityDownload] = useState([]);

  const getTagIDTraceability = useCallback(
    (startDate, endDate) => {
      setTraceabilityLoading(true);
      fetchtWrapper({
        endpoint_url: "traceability/getTagIDTraceability",
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        setLoading: setTraceabilityLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setTraceabilityTable(fetch_output.table_data);
            setTraceabilityDownload(fetch_output.download_data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get traceability data",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Get Tag ID Traceability), Please Contact Admin",
          });
        },
      });
    },
    [fetchtWrapper]
  );

  return {
    getTagIDTraceability,
    traceabilityTable,
    traceabilityLoading,
    traceabilityDownload,
  };
}

export default useGetTagIDTraceability;
