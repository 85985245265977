import { useCallback, useContext, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetMillCertSpecificationOption() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [millCertSpecificationOption, setMillCertSpecificationOption] =
    useState([]);

  const getMillCertSpecification = useCallback(() => {
    fetchWrapper({
      endpoint_url: "options/getMillCertSpecificationOption",
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setMillCertSpecificationOption(fetch_output.options);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content:
            "Network Error. Failed to get mill cert specification option",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content:
            "Failed to Respond (Mill Cert Specification Option), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  useEffect(() => {
    getMillCertSpecification();
  }, []);

  return { millCertSpecificationOption };
}

export default useGetMillCertSpecificationOption;
