import { Button, Modal, Result } from "antd";

export default function VerificationTimeOutModal({
  timeOut,
  handleContinue,
  handleQuit,
}) {
  return (
    <Modal open={timeOut} footer={null} closable={false} centered>
      <Result
        status="warning"
        title="You've been inactive for 4 hours"
        subTitle="Do you want to continue with this bundle?"
        extra={
          <>
            <Button type="primary" onClick={handleContinue}>
              Continue
            </Button>
            <Button onClick={handleQuit}>Quit</Button>
          </>
        }
      />
    </Modal>
  );
}
