import { Form } from "antd";
import CustomManagementForm from "../../common/CustomManagementForm";

const WEIGHT_MANAGEMENT_FORM = [
  {
    label: "Name",
    name: "name",
    type: "input",
    disabled: true,
  },
  {
    label: "Product",
    name: "product",
    type: "input",
    disabled: true,
  },
  {
    label: "Minimum",
    name: "minimum",
    type: "input-number",
  },
  {
    label: "Maximum",
    name: "maximum",
    type: "input-number",
  },
];

export default function WeightManagementForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [weightManagementForm] = Form.useForm();

  return (
    <CustomManagementForm
      form={weightManagementForm}
      formProperties={WEIGHT_MANAGEMENT_FORM}
      modalTitle={
        editData
          ? `Edit Weight Min Max - ${editData.name}(${editData.product})`
          : "Add Weight Min Max"
      }
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      editData={editData}
      setEditData={setEditData}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
}
