import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetAllRebarTest() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [rebarTestLoading, setRebarTestLoading] = useState(false);
  const [rebarTestData, setRebarTestData] = useState([]);

  const getAllRebarTest = useCallback(() => {
    setRebarTestLoading(true);
    fetchWrapper({
      endpoint_url: "testing/getAllRebarTest",
      setLoading: setRebarTestLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setRebarTestData(fetch_output.data);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Failed to Fetch (Rebar Test), Please Contact Admin",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content: "Failed to Response (Rebar Test), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  return { getAllRebarTest, rebarTestData, rebarTestLoading };
}

export default useGetAllRebarTest;
