import { Button, Col, Flex, Form, Typography, Upload } from "antd";
import CustomManagementForm from "../../common/CustomManagementForm";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const BAR_MARK_MANAGEMENT_FORM = [
  {
    label: "Bar Mark Name",
    name: "bar_mark_name",
    type: "input",
    required: true,
  },
];

export default function BarMarkManagementForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [barMarkManagementForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const handleUploadImage = (file) => {
    barMarkManagementForm.setFieldValue("bar_mark_image", file.file);
    const updatedFileList = [...fileList];
    updatedFileList[0].status = "done";
    setFileList(updatedFileList);
  };

  const handleRemoveImage = () => {
    barMarkManagementForm.setFieldValue("bar_mark_image", null);
    setFileList([]);
  };

  const onFinish = (value, key) => {
    const formData = new FormData();
    if (key != null) formData.append("bar_mark_key", key);
    formData.append("bar_mark_name", value["bar_mark_name"]);
    if (value["bar_mark_image"] instanceof File)
      formData.append("bar_mark_image", value["bar_mark_image"]);

    onSubmit(formData, key);
  };

  useEffect(() => {
    setFileList([]);
  }, [modalOpen]);

  return (
    <CustomManagementForm
      form={barMarkManagementForm}
      formProperties={BAR_MARK_MANAGEMENT_FORM}
      extraFormProperties={
        <Col span={24} style={{ padding: "10px" }}>
          <Form.Item
            name="bar_mark_image"
            children={
              <Flex vertical="vertical">
                <Typography.Text>Bar Mark Image</Typography.Text>
                <Upload
                  accept="image/x-png, image/jpeg"
                  listType="picture"
                  customRequest={handleUploadImage}
                  onRemove={handleRemoveImage}
                  fileList={fileList}
                  onChange={({ fileList }) => setFileList(fileList)}
                  showUploadList={{
                    showRemoveIcon: true,
                    showPreviewIcon: false,
                  }}
                  maxCount={1}
                  children={
                    <Button
                      icon={<UploadOutlined />}
                      children={"Upload Image"}
                      disabled={fileList.length > 0}
                    />
                  }
                />
              </Flex>
            }
          />
        </Col>
      }
      modalTitle={
        editData ? `Edit Bar Mark - ${editData.bar_mark_name}` : "Add Bar Mark"
      }
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      editData={editData}
      setEditData={setEditData}
      onSubmit={onFinish}
      loading={loading}
    />
  );
}
