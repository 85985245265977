import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetShiftData() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [shiftTableData, setShiftTableData] = useState([]);
  const [shiftColumn, setShiftColumn] = useState([]);
  const [shiftChartData, setShiftChartData] = useState([]);
  const [shiftDownload, setShiftDownload] = useState([]);

  const getShiftData = useCallback(
    (factory, diameter, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistics/getShiftData",
        params: {
          factory: factory,
          diameter: diameter,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            setShiftTableData(fetch_ouput.table_data);
            setShiftColumn(fetch_ouput.table_column);
            setShiftChartData(fetch_ouput.chart_data);
            setShiftDownload(fetch_ouput.download_data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_ouput.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Netword Error. Failed to get shift table",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content: "Failed to Respond (Shift Table), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );
  return {
    getShiftData,
    shiftTableData,
    shiftColumn,
    shiftChartData,
    shiftDownload,
  };
}

export default useGetShiftData;
