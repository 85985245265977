import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetDOProgressDetails() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewSummary, setPreviewSummary] = useState({});
  const [previewTable, setPreviewTable] = useState([]);

  const getDOProgressDetails = useCallback(
    (key, setPreviewModal, setSelectKey) => {
      setPreviewLoading(true);
      fetchWrapper({
        endpoint_url: "do_progress/getDOProgressDetails",
        params: {
          key: key,
        },
        setLoading: setPreviewLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setPreviewSummary(fetch_output.summary_data);
            setPreviewTable(fetch_output.table_data);
            setPreviewModal(true);
            setSelectKey(key);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content:
              "Network Error. Failed to get delivery order progress details",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Get Delivery Order Progress Details). Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getDOProgressDetails, previewLoading, previewSummary, previewTable };
}

export default useGetDOProgressDetails;
