import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useUpdateRebarReject() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [retakeLoading, setRetakeLoading] = useState(false);
  const [retakeSuccess, setRetakeSuccess] = useState(false);

  const updateRebarReject = useCallback(
    (rebar_key) => {
      return new Promise((resolve, reject) => {
        setRetakeLoading(true);
        setRetakeSuccess(false);
        fetchWrapper({
          endpoint_url: "ai_rebar_count/updateRebarReject",
          params: {
            rebar_key: rebar_key,
          },
          isLoading: setRetakeLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              setRetakeSuccess(true);
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
              setRetakeSuccess(false);
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            setRetakeSuccess(true);
            reject(false);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to retake Rebar image",
            });
            setRetakeSuccess(true);
            reject(false);
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Upload Rebar Rework), Please Contact Admin",
            });
            setRetakeSuccess(true);
            reject(false);
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { updateRebarReject, retakeLoading, retakeSuccess };
}

export default useUpdateRebarReject;
