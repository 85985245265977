import { useRef, useState, useEffect, useContext } from "react";
import { message, Button, Typography, Progress, Select, Flex } from "antd";
import {
  CameraOutlined,
  ThunderboltFilled,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { IndexContext } from "../../../context/IndexContext";
import checkIcon from "../../../assets/camera/icons8-check.svg";
import closeIcon from "../../../assets/camera/icons8-cancel.svg";
import "../../../styles/camera/Camera.css";
import CameraResultModal from "../CameraResultModal";

const { Title } = Typography;

export default function CameraFunc({
  content,
  shape,
  cameraSide,
  setFinalImage,
  isLoading,
  resultType,
  rebarCount,
  aiImageSrc,
  onResultConfirm,
  onResultRetake,
  retakeSuccess,
  retakeLoading,
  loadingReset,
}) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const circleRef = useRef(null);

  const { isLightTheme, messageApi } = useContext(IndexContext);
  // For handle the Image that Captured
  const [capturedImage, setCapturedImage] = useState(null);
  // For handle the Camera is Active or not
  const [cameraActive, setCameraActive] = useState(false);
  // For handle the Media Stream
  const [stream, setStream] = useState(null);
  // For handle the Device Camera Option
  const [cameraDevices, setCameraDevices] = useState([]);
  // For handle the Device Camera ID (To change the Camera)
  const [currentDeviceId, setCurrentDeviceId] = useState(null);
  // For handle the Camera Flash
  const [flash, setFlash] = useState(true);
  const idleTimeoutRef = useRef(null);

  // For handle the Camera Result Modal
  const [resultModal, setResultModal] = useState(false);
  const closeModal = () => setResultModal(false);

  // For Success Result Modal Confirm
  const onSuccessConfirm = () => {
    onResultConfirm();
    resetCameraStatus();
  };

  // For Fail Result Modal Confirm
  const onFailConfirm = () => {
    resetCameraStatus();
  };

  const onRetakeConfirm = () => {
    onResultRetake();
  };

  // For reset the Camera
  const resetCameraStatus = () => {
    closeModal();
    loadingReset();
    setCameraActive(false);
    setCapturedImage(null);
  };

  // For open the Camera
  const openCamera = () => {
    setCapturedImage(null);
    setCameraActive(true);
    setFlash(true);
  };

  // For call warning message
  const warningMessage = (message) => {
    messageApi.open({
      type: "warning",
      content: message,
    });
  };

  // Function to filter Front or Back Camera
  const filterCamera = (devices) => {
    try {
      if (cameraSide === "front") {
        const frontDevices = devices.filter((device) =>
          device.label.endsWith("front")
        );

        if (frontDevices.length === 0)
          throw new Error("No front camera devices found");
        return frontDevices;
      } else if (cameraSide === "back") {
        const backDevices = devices.filter((device) =>
          device.label.endsWith("back")
        );

        if (backDevices.length === 0)
          throw new Error("No back camera devices found");
        return backDevices;
      }
    } catch (error) {
      return devices;
    }
  };

  // Function to check the Camera Permission
  const checkPermissions = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "camera",
      });
      return permissionStatus.state;
    } catch (error) {
      warningMessage(error);
    }
  };

  // Function to ask for the Camera Permission
  const requestCameraAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });

      const cameraPermission = await checkPermissions();

      if (cameraPermission === "granted") {
        getVideoDevices();
      } else {
        warningMessage("Camera access not granted");
      }
    } catch (error) {
      warningMessage("Camera access denied or failed");
    }
  };

  // Function to get the Device Camera
  const getVideoDevices = async () => {
    const cameraPermissions = await checkPermissions();

    if (cameraPermissions == "granted") {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );

        const devicesMap = videoDevices
          .map((device) => ({
            value: device.deviceId,
            label: device.label,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        const deviceOptions = filterCamera(devicesMap);

        setCameraDevices(deviceOptions);
        if (videoDevices.length > 0) {
          setCurrentDeviceId(deviceOptions[0].value);
        }
      } else {
        console.error("enumerateDevices() not supported.");
      }
    } else {
      requestCameraAccess();
    }
  };

  // Function to start the camera
  const startVideo = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        // Set the Camera Resolution to 1920 x 1080
        const constraints = {
          video: {
            width: 1920,
            height: 1080,
            deviceId: { exact: currentDeviceId },
          },
        };

        startStream(constraints);

        // resetIdleTimeout();
      } catch (error) {
        message.error(error.message);
        setCameraActive(false);
      }
    } else {
      console.error("getUserMedia() not supported.");
    }
  };

  // Function to start the Media Stream
  const startStream = async (constraints) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setStream(stream);
      handleFlask(stream);
      handleStream(stream);
    } catch (error) {
      if (error.name === "NotAllowedError") {
        warningMessage("Camera access denied or blocked by the user");
      } else if (error.name === "NotReadableError") {
        warningMessage("Camera is currently in use by another application");
      } else {
        warningMessage("Camera error occurred");
      }
      setCameraActive(false);
    }
  };

  // Function to handle the flashlight (torch)
  const handleFlask = async (stream) => {
    try {
      const track = stream.getVideoTracks()[0];
      const capabilities = track.getCapabilities();

      if (flash) {
        // Check is the camera supports flashlight
        if (capabilities.torch) {
          // For enable the torch
          await track.applyConstraints({
            advanced: [{ torch: true }],
          });
        } else {
          warningMessage("This camera don't have Flash Light");
        }
      } else {
        // Disable torch if currently enabled
        if (capabilities.torch) {
          const currentSettings = track.getSettings();

          if (currentSettings.torch) {
            await track.applyConstraints({
              advanced: [{ torch: false }],
            });
          }
        }
      }
    } catch (error) {
      warningMessage("Failed to open Flash Light");
    }
  };

  // Function to handle attaching the stream to the video element
  const handleStream = (stream) => {
    if (videoRef.current) {
      // Set the video element's srcObject to the stream
      videoRef.current.srcObject = stream;
      // Play the video once the metadata is loaded
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play();
      };
    }
  };

  // Function to get the picture that captured
  const takePicture = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      // Set the desired aspect ratio
      const aspectRatio = 9 / 16;
      const videoHeight = video.videoHeight;
      const videoWidth = video.videoWidth;

      // Calculate the dimensions for the canvas
      const canvasHeight = videoHeight;
      const canvasWidth = canvasHeight * aspectRatio;

      // Resize the canvas to the new dimensions
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const context = canvas.getContext("2d");

      // Draw the video frame to the canvas
      context.drawImage(
        video,
        (videoWidth - canvasWidth) / 2,
        0,
        canvasWidth,
        canvasHeight,
        0,
        0,
        canvasWidth,
        canvasHeight
      );
      const dataJpegUrl = canvas.toDataURL("image/jpeg");

      setCapturedImage(dataJpegUrl);
      setFlash(false);

      stopVideo();
      clearTimeout(idleTimeoutRef.current);
    } else {
      console.error("Canvas or video element is not available.");
    }
  };

  // Function for closing the camera
  const stopVideo = () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        if (track.readyState === "live") {
          track.stop();
        }
      });
      setStream(null);
    }
  };

  // For retake the image
  const closePreview = () => {
    setCapturedImage(null);
    startVideo();
    // resetIdleTimeout();
  };

  // For confirm the image to upload
  const confirmImage = () => {
    setFinalImage(capturedImage);
  };

  // For handle change Camera
  const handleChange = (value) => {
    setCurrentDeviceId(value);
    stopVideo();
  };

  // To handle Camera Start/Stop based on cameraActive State
  useEffect(() => {
    if (cameraActive) {
      startVideo();
    } else {
      // Stop all tracks and clear the video element's srcObject
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => {
          if (track.readyState === "live") {
            track.stop();
          }
        });
        videoRef.current.srcObject = null;
      }
    }

    // Cleanup function to stop stream tracks and clear any timeouts
    return () => {
      clearTimeout(idleTimeoutRef.current);
      if (stream) {
        stream.getTracks().forEach((track) => {
          if (track.readyState === "live") {
            track.stop();
          }
        });
      }
    };
  }, [cameraActive, currentDeviceId, flash]);

  useEffect(() => {
    getVideoDevices();
  }, []);

  useEffect(() => {
    if (isLoading.progress === 100 && isLoading.res !== null)
      setResultModal(true);
  }, [isLoading]);

  useEffect(() => {
    if (retakeSuccess) resetCameraStatus();
  }, [retakeLoading, retakeSuccess]);

  return (
    <Flex vertical="vertical" gap="small">
      <Flex justify="space-between" gap="small" align="center">
        <Select
          options={cameraDevices}
          value={currentDeviceId}
          onChange={handleChange}
          style={{ width: "100%" }}
        />
        <Button
          icon={flash ? <ThunderboltFilled /> : <ThunderboltOutlined />}
          onClick={() => {
            setFlash(!flash);
          }}
        />
      </Flex>
      <div className="camera-component">
        <div className="camera-container">
          <div className="cam-frame">
            <div
              className="cam-subframe"
              style={{
                backgroundColor: isLightTheme ? "white" : "gray",
                height: shape === "circle" ? "85%" : "100%",
              }}
            >
              <div
                className="cam-window icon-window"
                // onClick={resetIdleTimeout}
                // onMouseMove={resetIdleTimeout}
              >
                {!cameraActive ? (
                  <>
                    <div className="camera-group">
                      <Button
                        shape="circle"
                        onClick={openCamera}
                        icon={<CameraOutlined style={{ fontSize: "100px" }} />}
                        style={{ width: "200px", height: "200px" }}
                      />
                      <Title level={4} style={{ textAlign: "center" }}>
                        {content}
                      </Title>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="camera-container">
                      {!capturedImage ? (
                        <div className="video-container">
                          <video
                            ref={videoRef}
                            autoPlay
                            className="video-frame"
                          />
                          {shape == "circle" && (
                            <div ref={circleRef} className="video-circle" />
                          )}
                          <Button
                            shape="circle"
                            className="capture-button"
                            onClick={takePicture}
                            style={{
                              border: isLightTheme
                                ? "3px solid black"
                                : "3px solid white",
                            }}
                          />
                        </div>
                      ) : (
                        <div className="preview-container">
                          {isLoading.state && (
                            <div className="loading-container">
                              <Progress
                                type="circle"
                                style={{
                                  backgroundColor: isLightTheme
                                    ? "white"
                                    : "black",
                                  borderRadius: "50%",
                                }}
                                percent={isLoading.progress}
                              />
                            </div>
                          )}
                          {shape == "circle" && (
                            <div ref={circleRef} className="video-circle" />
                          )}
                          <img
                            src={capturedImage}
                            alt="Captured"
                            className="preview-image"
                          />
                          <div className="preview-buttons">
                            <Button
                              className="close-button"
                              onClick={closePreview}
                              shape="circle"
                              disabled={isLoading.state}
                            >
                              <img src={closeIcon} alt="Close" />
                            </Button>
                            <Button
                              className="confirm-button"
                              onClick={confirmImage}
                              shape="circle"
                              disabled={isLoading.state}
                            >
                              <img src={checkIcon} alt="Confirm" />
                            </Button>
                          </div>
                        </div>
                      )}
                      <canvas
                        ref={canvasRef}
                        style={{ display: "none" }}
                      ></canvas>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CameraResultModal
        resultModal={resultModal}
        resultType={resultType}
        rebarCount={rebarCount}
        aiImageSrc={aiImageSrc}
        onResultConfirm={isLoading.res ? onSuccessConfirm : onFailConfirm}
        onResultRetake={onRetakeConfirm}
      />
    </Flex>
  );
}
