import { Column } from "@ant-design/plots";
import { Empty, Flex } from "antd";
import Title from "antd/es/skeleton/Title";

export default function InventoryBarChart({ data, chartTextColor }) {
  const config = {
    data,
    xField: "time",
    yField: "value",
    colorField: "type",
    group: true,
    axis: {
      x: {
        title: "Monthly",
        titleFill: chartTextColor,
        tickStroke: chartTextColor,
        labelFill: chartTextColor,
      },
      y: {
        title: "Quantity",
        titleFill: chartTextColor,
        tickStroke: chartTextColor,
        labelFill: chartTextColor,
      },
    },
    label: {
      text: "value",
      position: "inside",
    },
    legend: {
      size: false,
      color: {
        itemLabelFill: chartTextColor,
      },
    },
  };
  return (
    <div style={{ height: "24vh" }}>
      {data.length ? (
        <Column {...config} />
      ) : (
        <Flex align="center" justify="center" style={{ height: "100%" }}>
          <Empty />
        </Flex>
      )}
    </div>
  );
}
