import { Button, Flex, Image } from "antd";
import FunctionalButton from "../../common/FunctionalButton";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import DataTable from "../../common/DataTable";
import { useState } from "react";

export default function BarMarkManagementTable({
  tableData,
  handleEdit,
  handleDelete,
  deleteLoading,
}) {
  const [selectImage, setSelectImage] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [loadRowKey, setLoadRowKey] = useState(null);

  const BAR_MARK_COLUMN = [
    { title: "No.", dataIndex: "number", key: "number", width: 80 },
    {
      title: "Bar Mark Name",
      dataIndex: "bar_mark_name",
      key: "bar_mark_name",
      width: 120,
    },
    {
      title: "Bar Mark Image",
      dataIndex: "bar_mark_image",
      key: "bar_mark_image",
      width: 100,
      render: (bar_mark_image) => {
        return (
          <FunctionalButton
            buttonClickFunction={() => {
              setSelectImage(bar_mark_image);
              setPreviewVisible(true);
            }}
            icon={<EyeOutlined />}
            width="40px"
          />
        );
      },
    },
    {
      title: "Insert By",
      dataIndex: "insert_by",
      key: "insert_by",
      width: 100,
    },
    {
      title: "Insert Date Time",
      dataIndex: "insert_date_time",
      key: "insert_date_time",
      width: 140,
    },
    {
      title: "Last Update By",
      dataIndex: "last_update_by",
      key: "last_update_by",
      width: 100,
    },
    {
      title: "Last Update Date Time",
      dataIndex: "last_update_date_time",
      key: "last_update_date_time",
      width: 140,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_, record) => {
        return (
          <Flex gap="small">
            <FunctionalButton
              buttonClickFunction={() => handleEdit(record)}
              type=""
              width="40px"
              disabled={loadRowKey == record.key && deleteLoading}
              icon={<EditOutlined />}
            />
            <FunctionalButton
              buttonClickFunction={() => {
                handleDelete(record.key);
                setLoadRowKey(record.key);
              }}
              show_confirmation={true}
              confirmation_message={{
                title: "Confirmation",
                content: "Sure to delete this bar mark?",
              }}
              danger={true}
              width="40px"
              icon={<DeleteOutlined />}
              trigger_loading={loadRowKey == record.key && deleteLoading}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        table_column={BAR_MARK_COLUMN}
        table_data={tableData}
        pageSize={10}
      />
      <Image.PreviewGroup
        items={[selectImage]}
        preview={{
          visible: previewVisible,
          onVisibleChange: (value) => setPreviewVisible(value),
        }}
      />
    </>
  );
}
