import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetProductList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [productListLoading, setProductListLoading] = useState(false);
  const [productList, setProductList] = useState([]);

  const getProductList = useCallback(() => {
    setProductListLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getProductList",
      setLoading: setProductListLoading,
      onSuccess: (fetch_ouput) => {
        if (fetch_ouput.status) {
          setProductList(fetch_ouput.data);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_ouput.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get product list",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content: "Failed to Respond (Product List), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  return { getProductList, productList, productListLoading };
}

export default useGetProductList;
