import { Form } from "antd";
import CustomManagementForm from "../../common/CustomManagementForm";

const MILL_CERT_MANAGEMENT_FORM = [
  {
    label: "Mill Cert Type",
    name: "mill_cert_type",
    type: "input",
    required: true,
  },
  {
    label: "Description",
    name: "description",
    type: "input",
    required: true,
  },
  {
    label: "Bend Test",
    name: "bend_test",
    type: "input",
    required: true,
  },
  {
    label: "Rebend Test",
    name: "rebend_test",
    type: "input",
    required: true,
  },
  {
    label: "Statement",
    name: "statement",
    type: "text-area",
    row: 5,
    required: true,
  },
  {
    label: "Remark",
    name: "remark",
    type: "text-area",
    row: 5,
    required: true,
  },
  {
    label: "Specification",
    name: "specification",
    type: "input",
    required: true,
  },
];

export default function MillCertManagementForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [millCertManagementForm] = Form.useForm();

  return (
    <CustomManagementForm
      form={millCertManagementForm}
      formProperties={MILL_CERT_MANAGEMENT_FORM}
      modalTitle={
        editData
          ? `Edit Mill Cert - ${editData.mill_cert_type}`
          : "Add Mill Cert"
      }
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      editData={editData}
      setEditData={setEditData}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
}
