import { Layout } from "antd";
import { useEffect, useState, useContext } from "react";
import { IndexContext } from "../../context/IndexContext";
import { useNavigate } from "react-router-dom";
import { supervisor } from "../../configs/configs.js";
import CameraFunc from "../../components/ai_rebar_count/camera/CameraFunc";
import useUpdateLoginRecord from "../../functions/LoginRecord/updateLoginRecord";
import useCheckUserLoginRecord from "../../functions/LoginRecord/checkUserLoginRecord";

export default function LoginRecordPage() {
  // For navigate
  let navigate = useNavigate();

  // For image upload
  const [isLoading, setIsLoading] = useState({
    state: false,
    progress: 0,
    res: null,
  });

  // For check usertheme
  const { isLightTheme } = useContext(IndexContext);

  // Api Function to update the Login Record
  const { updateLoginRecord, resultType, userType, userStage } =
    useUpdateLoginRecord();
  // Api Function to Check User Login Record
  const { checkUserLoginRecord } = useCheckUserLoginRecord();

  // Function to confirm upload the image
  const confirmImage = (url) => {
    setIsLoading((prevState) => ({ ...prevState, state: true }));
    loadProgress();
    updateLoginRecord(url, setIsLoading);
  };

  // To reset the Loading State and Progress
  const loadingReset = () => {
    setIsLoading({ state: false, progress: 0, res: null });
  };

  // Function for loading the progress
  function loadProgress() {
    const interval = setInterval(() => {
      setIsLoading((prevState) => {
        if (prevState.progress >= 90) {
          clearInterval(interval);
          return prevState;
        }
        return { ...prevState, progress: prevState.progress + 1 };
      });
    }, 1000);
  }

  const onResultConfirm = () => {
    if (userType === supervisor) {
      navigate("/bundle_list", { replace: true });
    } else {
      if (userStage === 4) {
        navigate("/delivery_order_connection", { replace: true });
      } else navigate("/ai_rebar_count", { replace: true });
    }
  };

  // To navigate when Image Upload finish
  useEffect(() => {
    checkUserLoginRecord();
  }, []);

  return (
    <div>
      <Layout style={{ height: "100vh" }}>
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: isLightTheme ? "#E6F4F1" : undefined,
          }}
        >
          <CameraFunc
            content={"Please take a selfie for the login record"}
            shape={""}
            cameraSide={"front"}
            setFinalImage={confirmImage}
            isLoading={isLoading}
            resultType={resultType}
            onResultConfirm={onResultConfirm}
            loadingReset={loadingReset}
          />
        </Layout.Content>
      </Layout>
    </div>
  );
}
