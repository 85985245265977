import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetStatisticWipData() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [statisticWipTableData, setStatisticWipTableData] = useState([]);
  const [statisticWipColumn, setStatisticWipColumn] = useState([]);
  const [statisticWipChartData, setStatisticChartData] = useState([]);
  const [statisticWipDownload, setStatisticDownload] = useState([]);

  const getStatisticWipData = useCallback(
    (factory, diameter, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistics/getStatisticWipData",
        params: {
          factory: factory,
          diameter: diameter,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setStatisticWipTableData(fetch_output.table_data);
            setStatisticWipColumn(fetch_output.table_column);
            setStatisticChartData(fetch_output.chart_data);
            setStatisticDownload(fetch_output.download_data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Netword Error. Failed to get statistic wip table",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Statistic WIP Table), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getStatisticWipData,
    statisticWipTableData,
    statisticWipColumn,
    statisticWipChartData,
    statisticWipDownload,
  };
}

export default useGetStatisticWipData;
