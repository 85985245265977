import { QuestionCircleOutlined } from "@ant-design/icons";
import { Flex, Tooltip } from "antd";

export default function CustomTooltip({ title, content }) {
  return (
    <Flex gap={"middle"}>
      <span>{title}</span>
      <Tooltip
        title={
          <Flex vertical="vertical">
            {content.map((data) => (
              <span>
                <strong>{`${data.type}: `}</strong>
                {data.description}
              </span>
            ))}
          </Flex>
        }
        children={<QuestionCircleOutlined />}
      />
    </Flex>
  );
}
