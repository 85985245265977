import { useCallback, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useUpdateBarMark() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const updateBarMark = useCallback(
    (data, getBarMarkList, setFormLoading, setBarMarkModal) => {
      return new Promise((resolve, reject) => {
        setFormLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/updateBarMark",
          set_content_type_header: false,
          body: data,
          setLoading: setFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              getBarMarkList();
              setBarMarkModal(false);
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to update bar mark",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Update Bar Mark), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateBarMark };
}

export default useUpdateBarMark;
