import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useRemoveBundle() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [removeLoading, setRemoveLoading] = useState(false);

  const removeBundle = useCallback(
    (
      key,
      handleDOProgressList,
      getDOProgressDetails,
      selectKey,
      setPreviewModal,
      setSelectKey
    ) => {
      return new Promise((resolve, reject) => {
        setRemoveLoading(true);
        fetchWrapper({
          endpoint_url: "do_progress/removeBundle",
          params: {
            key: key,
          },
          setLoading: setRemoveLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              setPreviewModal(false);
              handleDOProgressList();
              getDOProgressDetails(selectKey, setPreviewModal, setSelectKey);
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(false);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to remove bundle",
            });
            reject(false);
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Remove Bundle), Please Contact Admin",
            });
            reject(false);
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { removeBundle, removeLoading };
}

export default useRemoveBundle;
