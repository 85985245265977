import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetDeliveryBundles() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [deliveryChart, setDeliveryChart] = useState([]);
  const [deliverySummary, setDeliverySummary] = useState({});

  const getDeliveryBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getDeliveryBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setDeliveryChart(fetch_output.chart_data);
            setDeliverySummary(fetch_output.summary_data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get delivery bundles",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Delivery Bundles), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getDeliveryBundles, deliveryChart, deliverySummary };
}

export default useGetDeliveryBundles;
