import { Form } from "antd";
import CustomManagementForm from "../../common/CustomManagementForm";

const MECHANICAL_MANAGEMENT_FORM = [
  {
    label: "Mechanical Properties",
    name: "mechanical_name",
    type: "input",
    disabled: true,
    quarter: true,
  },
  {
    label: "Abbreviation",
    name: "mechanical_short_name",
    type: "input",
    disabled: true,
    quarter: true,
  },
  {
    label: "Grade",
    name: "grade",
    type: "input",
    disabled: true,
    quarter: true,
  },
  {
    label: "Minimum",
    name: "minimum",
    type: "input-number",
  },
  {
    label: "Maximum",
    name: "maximum",
    type: "input-number",
  },
];

export default function MechanicalManagementForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [mechanicalManagementForm] = Form.useForm();

  return (
    <CustomManagementForm
      form={mechanicalManagementForm}
      formProperties={MECHANICAL_MANAGEMENT_FORM}
      modalTitle={
        editData
          ? `Edit Properties Min Max - ${editData.mechanical_name} (${editData.grade})`
          : "Add Properties Min Max"
      }
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      editData={editData}
      setEditData={setEditData}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
}
