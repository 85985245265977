import { useEffect } from "react";
import CustomModal from "./CustomModal";
import { Button, Flex, Form, Row } from "antd";
import CustomFormItems from "./CustomFormItems";

export default function CustomManagementForm({
  form,
  formProperties,
  extraFormProperties = <></>,
  modalTitle,
  modalOpen,
  setModalOpen,
  modalWidth = 800,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const onFinish = (value) => {
    if (editData) {
      onSubmit(value, editData["key"]);
    } else onSubmit(value, null);
  };

  useEffect(() => {
    if (modalOpen == false) {
      form.resetFields();
      setEditData(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (editData) form.setFieldsValue(editData);
  }, [editData]);

  return (
    <CustomModal
      title={modalTitle}
      close_icon={true}
      visible={modalOpen}
      onCancel={() => {
        setModalOpen(false);
      }}
      width={modalWidth}
      content={
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <CustomFormItems
              form_properties={formProperties}
              form_label={true}
            />
            {extraFormProperties}
          </Row>
          <Flex gap="middle" justify="flex-end">
            <Form.Item
              children={
                <Button
                  onClick={() => setModalOpen(false)}
                  type="link"
                  children="Cancel"
                  disabled={loading}
                />
              }
            />
            <Form.Item
              children={
                <Button
                  type="primary"
                  htmlType="submit"
                  children={editData ? "Update" : "Create"}
                  loading={loading}
                />
              }
            />
          </Flex>
        </Form>
      }
    />
  );
}
