import { Form } from "antd";
import useGetUserTypeOption from "../../../functions/Options/getUserTypeOption";
import useGetFactoryOption from "../../../functions/Options/getFactoryOption";
import CustomManagementForm from "../../common/CustomManagementForm";

export default function UserManagementForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [userManagementForm] = Form.useForm();

  const { userTypeOption } = useGetUserTypeOption();
  const { factoryOption } = useGetFactoryOption();

  const USER_MANAGEMENT_FORM = [
    {
      label: "Username",
      name: "username",
      type: "input",
      required: true,
    },
    {
      label: "Password",
      name: "password",
      type: "input",
      required: true,
    },
    {
      label: "User Type",
      name: "user_type",
      type: "select",
      options: userTypeOption,
      required: true,
    },
    {
      label: "Factory",
      name: "factory",
      type: "select",
      options: factoryOption,
      required: true,
    },
  ];

  return (
    <CustomManagementForm
      form={userManagementForm}
      formProperties={USER_MANAGEMENT_FORM}
      modalTitle={editData ? `Edit User - ${editData.username}` : "Add User"}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      editData={editData}
      setEditData={setEditData}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
}
