import { Button, Card, Flex, Input, Typography } from "antd";
import DeliveryOrderConnectionTable from "../../components/delivery_order_connection/DeliveryOrderConnectionTable";
import { useEffect, useState } from "react";
import DeliveryOrderConnectionModal from "../../components/delivery_order_connection/DeliveryOrderConnectionModal";
import useGetActivateDeliveryOrder from "../../functions/DeliveryOrderConnection/getActivateDeliveryOrder";
import useAddDeliveryOrderConnection from "../../functions/DeliveryOrderConnection/addDeliveryOrderConnection";
import useCheckDeliveryOrderConnection from "../../functions/DeliveryOrderConnection/checkDeliveryOrderConnection";
import { SearchOutlined } from "@ant-design/icons";

export default function DeliveryOrderConnectionPage() {
  // For handle connection modal
  const [connectionModal, setConnectionModal] = useState(false);
  // For handle specific delivery order data
  const [connectionData, setConnectionData] = useState(null);
  // For handle specific delivery order key
  const [connectionKey, setConnectionKey] = useState(null);
  // For handle filter active delivery order data
  const [filterDeliverOrder, setFilterDeliveryOrder] = useState([]);
  // For handle delivery order number for filtering
  const [doNumber, setDoNumber] = useState(null);

  // For open connection modal
  const handleLoadDetails = (value) => {
    setConnectionData(value);
    setConnectionModal(true);
  };

  // Api Function to get Activate Delivery Order data
  const { getActivateDeliveryOrder, activateDeliveryOrder, isLoading } =
    useGetActivateDeliveryOrder();
  // Api Function to add Delivery Order Connection for user
  const { addDeliveryOrderConnection, addLoading } =
    useAddDeliveryOrderConnection();
  // Api Function to check user Delivery Order Connection
  const { checkDeliveryOrderConnection } = useCheckDeliveryOrderConnection();

  // Function to call add delivery order api
  const handleStartCount = (value) =>
    addDeliveryOrderConnection(connectionKey, value);
  // Function to filter data by do number
  const handleSearch = () => {
    const filterData = activateDeliveryOrder.filter((item) => {
      return String(item.delivery_order).includes(doNumber);
    });

    setFilterDeliveryOrder(filterData);
  };

  // For get activate delivery order data and check user delivery order connection
  useEffect(() => {
    checkDeliveryOrderConnection();
    getActivateDeliveryOrder();
  }, []);

  // For filter the activate delivery order data
  useEffect(() => {
    setFilterDeliveryOrder(activateDeliveryOrder);
  }, [activateDeliveryOrder]);

  return (
    <>
      <Card
        className="context-card"
        loading={isLoading}
        title={
          <Flex gap={"small"} wrap align="center" justify="center">
            <Typography.Text children={"DO: "} />
            <Input
              style={{ width: "200px" }}
              disabled={isLoading}
              value={doNumber}
              onChange={(e) => setDoNumber(e.target.value)}
            />
            <Button
              icon={<SearchOutlined />}
              onClick={handleSearch}
              disabled={isLoading}
            />
          </Flex>
        }
      >
        <DeliveryOrderConnectionTable
          tableData={filterDeliverOrder}
          handleLoadDetails={handleLoadDetails}
          setConnectionKey={setConnectionKey}
        />
      </Card>
      <DeliveryOrderConnectionModal
        modalOpen={connectionModal}
        setModalOpen={setConnectionModal}
        connectionData={connectionData}
        handleStartCount={handleStartCount}
        addLoading={addLoading}
      />
    </>
  );
}
