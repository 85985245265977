import { Button, Divider, Flex, Form, Input, Typography } from "antd";
import CustomModal from "../common/CustomModal";
import { useEffect } from "react";

export default function DeliverOrderConnectionModal({
  modalOpen,
  setModalOpen,
  connectionData,
  handleStartCount,
  addLoading,
}) {
  const [deliveryOrderConnection] = Form.useForm();

  useEffect(() => {
    if (modalOpen == false) {
      deliveryOrderConnection.resetFields();
    } else {
      const initialValues = connectionData.reduce((acc, data) => {
        if (data.type === "input") {
          acc[data.name] = data.value || "";
        }
        return acc;
      }, {});
      deliveryOrderConnection.setFieldsValue(initialValues);
    }
  }, [modalOpen]);

  return (
    <CustomModal
      title={"Loading Details"}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <Form form={deliveryOrderConnection} onFinish={handleStartCount}>
          {connectionData &&
            connectionData.map((data) => {
              return (
                <>
                  <Divider
                    orientation="left"
                    orientationMargin="0"
                    style={{ borderColor: "#C0C0C0" }}
                    children={<strong>{data.name}</strong>}
                  />
                  {data.type == "text" ? (
                    data.value.split("\n").map((line, index) => (
                      <Typography.Text key={index}>
                        {line}
                        <br />
                      </Typography.Text>
                    ))
                  ) : (
                    <Form.Item
                      name={data.name}
                      rules={[
                        {
                          required: true,
                          message: `Please input lorry plate`,
                        },
                      ]}
                      children={<Input />}
                    />
                  )}
                </>
              );
            })}
          <Flex gap="middle" justify="flex-end" style={{ paddingTop: "20px" }}>
            <Button
              type="link"
              children="Cancel"
              disabled={addLoading}
              onClick={() => {
                setModalOpen(false);
              }}
            />
            <Button
              type="primary"
              htmlType="submit"
              children={"Start Count"}
              loading={addLoading}
            />
          </Flex>
        </Form>
      }
    />
  );
}
