import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetStuckBundles() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [stuckBundlesChart, setStuckBundlesChart] = useState([]);

  const getStuckBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getStuckBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setStuckBundlesChart(fetch_output.data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get stuck bundles",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content: "Failed to Respond (Stuck Bundles), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getStuckBundles, stuckBundlesChart };
}

export default useGetStuckBundles;
