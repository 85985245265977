import useFetch from "../../../hooks/useFetch";
import { useCallback, useContext, useState } from "react";
import { IndexContext } from "../../../context/IndexContext";

function useGetMillCertList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [millCertLoading, setMillCertLoading] = useState(false);
  const [millCertList, setMillCertList] = useState([]);

  const getMillCertList = useCallback(() => {
    setMillCertLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getMillCertList",
      setLoading: setMillCertLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setMillCertList(fetch_output.data);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.message,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get mill cert list",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content:
            "Failed to Respond (Get Mill Cert List), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  return { getMillCertList, millCertList, millCertLoading };
}

export default useGetMillCertList;
