import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetUserList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [userListLoading, setUserListLoading] = useState(false);
  const [userList, setUserList] = useState([]);

  const getUserList = useCallback(() => {
    setUserListLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getUserList",
      setLoading: setUserListLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setUserList(fetch_output.data);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get user list",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content: "Failed to Respond (User List), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  return { getUserList, userList, userListLoading };
}

export default useGetUserList;
