import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useAddRebarManually() {
  const [fetchWrapper] = useFetch();
  const [rebarFormLoading, setRebarFormLoading] = useState(false);
  const { messageApi } = useContext(IndexContext);

  const addRebarManually = useCallback(
    (rebarData, tagID, imageName, setState, setTagId, reset) => {
      return new Promise((resolve, reject) => {
        setRebarFormLoading(true);
        fetchWrapper({
          endpoint_url: "ai_rebar_count/addRebar",
          params: {
            rebar_data: rebarData,
            tag_id: tagID,
            image_name: imageName,
          },
          setLoading: setRebarFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              setState("tagState");
              reset();
              setTagId(null);

              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to add rebar",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content: "Failed to Respond (Add Rebar), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { addRebarManually, rebarFormLoading };
}

export default useAddRebarManually;
