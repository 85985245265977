import { useCallback, useContext, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetUserTypeOption() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [userTypeOption, setUserTypeOption] = useState([]);

  const getUserTypeOption = useCallback(() => {
    fetchWrapper({
      endpoint_url: "options/getUserTypeOption",
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setUserTypeOption(fetch_output.options);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get user type option",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content: "Failed to Respond (User Type Option), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  useEffect(() => {
    getUserTypeOption();
  }, []);

  return { userTypeOption };
}

export default useGetUserTypeOption;
