import { useContext, useEffect, useState } from "react";
import { IndexContext } from "../../../context/IndexContext";
import ProductionChart from "./ProductionChart";
import ProductionTable from "./ProductionTable";

export default function ProductionPage({ chartData, tableData, tableColumn }) {
  const { isLightTheme } = useContext(IndexContext);
  const [chartTextColor, setChartTextColor] = useState(
    isLightTheme ? "#000000" : "#ffffff"
  );

  useEffect(() => {
    setChartTextColor(isLightTheme ? "#000000" : "#ffffff");
  }, [isLightTheme]);

  return (
    <>
      <ProductionChart chartData={chartData} chartTextColor={chartTextColor} />
      <ProductionTable tableColumn={tableColumn} tableData={tableData} />
    </>
  );
}
