import { Flex } from "antd";

const VERIFY_SUMMARY = {
  adjust: (
    <Flex align="center">
      <div className="circle" style={{ backgroundColor: "#D9D9D9" }} />
      Adjusted
    </Flex>
  ),
  non_adjust: (
    <Flex align="center">
      <div className="circle" style={{ backgroundColor: "#1890FF" }} />
      Non-Adjusted
    </Flex>
  ),
};

export default function IndicatorDonutSummary({ data }) {
  return (
    <Flex>
      <div className="flexbox-container-col" style={{ minWidth: "180px" }}>
        {VERIFY_SUMMARY.adjust}
        {VERIFY_SUMMARY.non_adjust}
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <span>{`:`}</span>
        <span>{`:`}</span>
      </div>
      <div
        className="flexbox-container-col"
        style={{ flexShrink: "0", flexGrow: "0" }}
      >
        <span>{data.adjusted}</span>
        <span>{data.non_adjust}</span>
      </div>
    </Flex>
  );
}
