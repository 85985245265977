import { Card, Col, Flex, Row } from "antd";
import IndicatorDonutChart from "./indicator/IndicatorDonutChart";
import IndicatorLineChart from "./indicator/IndicatorLineChart";
import IndicatorDonutSummary from "./indicator/IndicatorDonutSummary";

export default function IndicatorCard({
  chartTextColor,
  donutChartData,
  lineChartData,
  loading,
}) {
  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} sm={9}>
        <Card
          title={<strong>Breakdown of Verified Bundles</strong>}
          style={{ height: "100%" }}
          loading={loading}
        >
          <Flex
            vertical="vertical"
            align="center"
            justify="space-around"
            style={{ height: "100%" }}
          >
            <IndicatorDonutChart data={donutChartData} />
            <IndicatorDonutSummary data={donutChartData} />
          </Flex>
        </Card>
      </Col>
      <Col xs={24} sm={15}>
        <Card
          title={<strong>Current Production Output vs 7 Days Average</strong>}
          style={{ height: "100%" }}
          loading={loading}
        >
          <IndicatorLineChart
            data={lineChartData}
            chartTextColor={chartTextColor}
          />
        </Card>
      </Col>
    </Row>
  );
}
