import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useRemoveDeliverOrder() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [deliverOrderDeleteLoading, setDeliverOrderDeleteLoading] =
    useState(false);

  const removeDeliverOrder = useCallback(
    (key, handleDeliveryOrderList) => {
      return new Promise((resolve, reject) => {
        setDeliverOrderDeleteLoading(true);
        fetchWrapper({
          endpoint_url: "delivery_order/removeDeliveryOrder",
          params: {
            key: key,
          },
          setLoading: setDeliverOrderDeleteLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              handleDeliveryOrderList();
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to delete deliver order",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Delete Deliver Order), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { removeDeliverOrder, deliverOrderDeleteLoading };
}

export default useRemoveDeliverOrder;
