import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useSnapRebarImage() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const snapRebarImage = useCallback(
    (
      url,
      tagId,
      checkRebarStatus,
      setImageUploading,
      resetErrorCount,
      addErrorCount,
      setResultType
    ) => {
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "ai_rebar_count/snapRebarImage",
          params: {
            image_url: url,
            tag_key: tagId.key,
            tag_id: tagId.value,
          },
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              checkRebarStatus(
                fetch_output.key,
                setImageUploading,
                resetErrorCount,
                addErrorCount,
                setResultType
              );
              resetErrorCount();
            } else {
              addErrorCount();
              setImageUploading({ progress: 100, state: false, res: false });
              setResultType("upload_fail");
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            setImageUploading({ progress: 100, state: false, res: false });
            setResultType("upload_fail");
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            setImageUploading({ progress: 100, state: false, res: false });
            setResultType("upload_fail");
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to upload Rebar image",
            });
          },
          onResponseError: () => {
            setImageUploading({ progress: 100, state: false, res: false });
            setResultType("upload_fail");
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Upload Rebar Image), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { snapRebarImage };
}

export default useSnapRebarImage;
