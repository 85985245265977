import { Button, Card, Flex, Image } from "antd";
import DataTable from "../../../components/common/DataTable";
import { EyeOutlined } from "@ant-design/icons";
import useGetAllCameraTest from "../../../functions/Test/getAllCameraTest";
import { useEffect, useState } from "react";
import useGetAllRebarTest from "../../../functions/Test/getAllRebarTest";

export default function TableDataTest() {
  // For handle the Camera Test Image Preview
  const [previewVisible, setPreviewVisible] = useState(false);
  // For handle the Camera Test Data Image Select
  const [selectedImage, setSelectedImage] = useState(null);

  // Api Function to Get All Camera Test Data
  // const { getAllCameraTest, cameraTestData, cameraTestLoading } =
  //   useGetAllCameraTest();

  const { getAllRebarTest, rebarTestData, rebarTestLoading } =
    useGetAllRebarTest();

  // For get the Camera Test Image Data
  const handlePreview = (image) => {
    setSelectedImage(image);
    setPreviewVisible(true);
  };

  const TEST_TABLE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Image Name",
      dataIndex: "image_name",
      key: "image_name",
      width: 240,
    },
    {
      title: "AI Image1 Name",
      dataIndex: "ai_image1_name",
      key: "ai_image1_name",
      width: 240,
    },
    {
      title: "AI Image2 Name",
      dataIndex: "ai_image2_name",
      key: "ai_image2_name",
      width: 240,
    },
    {
      title: "AI Image3 Name",
      dataIndex: "ai_image3_name",
      key: "ai_image3_name",
      width: 240,
    },
    {
      title: "AI Count",
      dataIndex: "ai_count",
      key: "ai_count",
      width: 120,
    },
    {
      title: "Insert Date Time",
      dataIndex: "insert_date_time",
      key: "insert_date_time",
      width: 240,
    },
    {
      title: "Insert By",
      dataIndex: "insert_by",
      key: "insert_by",
      width: 120,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 120,
      render: (_, record) => {
        return (
          <Flex>
            <Button
              type="ghost"
              icon={<EyeOutlined />}
              onClick={() => handlePreview(record.action)}
            />
          </Flex>
        );
      },
    },
  ];

  useEffect(() => {
    // getAllCameraTest();
    getAllRebarTest();
  }, []);

  return (
    <>
      <Card
        title={"Testing Data"}
        style={{ width: "100%", height: "100%" }}
        loading={rebarTestLoading}
      >
        <DataTable
          table_column={TEST_TABLE_COLUMN}
          table_data={rebarTestData}
        />
        <Image.PreviewGroup
          items={selectedImage}
          preview={{
            visible: previewVisible,
            onVisibleChange: (value) => setPreviewVisible(value),
          }}
        />
      </Card>
    </>
  );
}
