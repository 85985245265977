import Camera from "../../../components/ai_rebar_count/camera/CameraFunc";
import { useState } from "react";
import { Col, Flex, Input, Row } from "antd";

export default function DownloadTest() {
  const [imageUploading, setImageUploading] = useState({
    state: false,
    progress: 0,
    res: null,
  });
  const tagId = "123456,BR-12-B-240910-1135";
  const dummyResult = "ai_process_success";
  const rebarCount = 0;
  const aiImageSrc = null;

  // Function to load progress after confirm the image
  function loadProgress() {
    const interval = setInterval(() => {
      setImageUploading((prevState) => {
        if (prevState.progress >= 90) {
          clearInterval(interval);
          return prevState;
        }
        return { ...prevState, progress: prevState.progress + 1, state: true };
      });
    }, 1000);
  }

  const loadingReset = () => {
    setImageUploading({ state: false, progress: 0, res: null });
  };

  // For download Captured Rebar Image
  const handleDownloadImageConfirm = (value) => {
    loadProgress();
    downloadImage(value);
  };

  const onResultConfirm = () => {};

  // For generate a Captured Rebar Image Names
  const generateFileName = () => {
    const now = new Date();
    const date = now.toISOString().slice(0, 10);
    const time = now.toTimeString().slice(0, 8).replace(/:/g, "-");
    return `${date}_${time}.jpeg`;
  };

  // Fucntiom for download Image
  const downloadImage = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = generateFileName();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setImageUploading({ progress: 100, state: false, res: true });
  };

  return (
    <Flex justify="center">
      <div className="responsive-row">
        <Flex vertical="vertical" gap="small">
          <Row>
            <Col className="responsive-col">
              <Input addonBefore="Tag ID:" value={tagId} readOnly />
            </Col>
          </Row>
          <Camera
            content={"Please take a picture with Circle"}
            shape={"circle"}
            cameraSide={"back"}
            setFinalImage={handleDownloadImageConfirm}
            isLoading={imageUploading}
            resultType={dummyResult}
            rebarCount={rebarCount}
            aiImageSrc={aiImageSrc}
            onResultConfirm={onResultConfirm}
            loadingReset={loadingReset}
          />
        </Flex>
      </div>
    </Flex>
  );
}
