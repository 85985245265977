import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useUpdateLoginRecord() {
  const [fetchWrapper] = useFetch();
  const [resultType, setResultType] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userStage, setUserStage] = useState(null);
  const { messageApi } = useContext(IndexContext);

  const updateLoginRecord = useCallback(
    (url, setIsLoading) => {
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "login_record/updateLoginRecord",
          params: {
            image_url: url,
          },
          onSuccess: (fetch_output) => {
            if (fetch_output.status === true) {
              setIsLoading({ progress: 100, state: false, res: true });
              setUserType(fetch_output.user_type);
              setUserStage(fetch_output.stage);
              setResultType(fetch_output.result_type);
            } else {
              setIsLoading({ progress: 100, state: false, res: false });
              setResultType(fetch_output.result_type);
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to update login record",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Bundle List Summary), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateLoginRecord, resultType, userType, userStage };
}

export default useUpdateLoginRecord;
