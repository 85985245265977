import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useRemoveUser() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [userDeleteLoading, setUserDeleteLoading] = useState(false);

  const removeUser = useCallback(
    (key, getUserList) => {
      return new Promise((resolve, reject) => {
        setUserDeleteLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/removeUser",
          params: {
            key: key,
          },
          setLoading: setUserDeleteLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              getUserList();
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to remove user",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content: "Failed to Respond (Remove User), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { removeUser, userDeleteLoading };
}

export default useRemoveUser;
