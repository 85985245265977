export default function DeliveryBundlesSummary({ summaryData }) {
  return (
    <>
      <div className="flexbox-container-col">
        <strong>Top 1</strong>
        <strong>Top 2</strong>
        <strong>Top 3</strong>
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <strong>{":"}</strong>
        <strong>{":"}</strong>
        <strong>{":"}</strong>
      </div>
      <div className="flexbox-container-col">
        <span>{summaryData.top_diameter}</span>
        <span>{summaryData.second_diameter}</span>
        <span>{summaryData.last_diameter}</span>
      </div>
    </>
  );
}
