// all const definition
const backend_port = ":5081"; // define the backend port
export const route_basename = "/"; // for defining the base route for BrowserRouter

// get the backend url
let current_url = "";
if (window.location.href.split(":").length == 2) {
  //frontend url has no port number
  current_url = window.location.href.split("/").slice(0, 3);
  current_url[current_url.length - 1] += backend_port;
  current_url = current_url.join("/");
} else {
  current_url =
    window.location.href.split(":").slice(0, 2).join(":") + backend_port;
}

export const backend_url = current_url + "/"; // eg. "http://localhost:5011/";
export const refresh_token_url = "user_management/refreshUser";

export const operator = 4;
export const supervisor = 3;
export const manager = 2;
export const account = 5;
export const qcOperator = 6;

export const ignoreRoutes = ["LoginPage", "LoginRecordPage"];
export const disabledUserTypesConfig = {
  DeliveryOrderConnectionPage: [account, operator],
  DashboardPage: [operator, supervisor, account, qcOperator],
  StatisticsPage: [operator, supervisor, account, qcOperator],
  TraceabilityPage: [operator, supervisor, account, qcOperator],
  DOProgressPage: [operator, account, qcOperator],
  DeliveryOrderPage: [operator, qcOperator],
  MaterialPropertiesPage: [operator, supervisor, account],
  AIRebarCountPage: [account, qcOperator],
  BundleListPage: [operator, account, qcOperator],
  RebarVerificationPage: [operator, account, qcOperator],
  ConfigurationPage: [operator, supervisor, account, qcOperator],
};

export const ignoreLabels = [
  "LoginPage",
  "LoginRecordPage",
  "DeliveryOrderConnectionPage",
  "RebarVerificationPage",
  "ChangePassword",
];
export const userAccessLabel = {
  test: [operator, supervisor, manager, account, qcOperator],
  ai_rebar_count: [account, qcOperator],
  bundle_list: [operator, account, qcOperator],
  analytics: [operator, supervisor, account, qcOperator],
  digital_mill_cert: [operator],
  do_progress: [account, qcOperator],
  delivery_order: [qcOperator],
  material_properties: [supervisor, account],
  configuration: [operator, supervisor, account, qcOperator],
};
