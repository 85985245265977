import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";
import useDownloadFile from "../DownloadFile/downloadFile";

function useDownloadTraceability() {
  const [fetchtWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { downloadFile } = useDownloadFile();

  const downloadTraceability = useCallback(
    (download_data) => {
      setDownloadLoading(true);
      fetchtWrapper({
        endpoint_url: "traceability/downloadTraceability",
        params: {
          download: download_data,
        },
        setLoading: setDownloadLoading,
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            downloadFile(fetch_ouput.folder_path, fetch_ouput.file_name);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_ouput.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content:
              "Network Error. Failed to download traceability excel file",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Download Traceability Excel), Please Contact Admin",
          });
        },
      });
    },
    [fetchtWrapper]
  );
  return { downloadTraceability, downloadLoading };
}

export default useDownloadTraceability;
