import { Button, Divider, Flex, Form, Row } from "antd";
import CustomModal from "../../common/CustomModal";
import { useEffect } from "react";
import CustomFormItems from "../../common/CustomFormItems";

const HEAT_NUMBER_FORM = [
  {
    label: "Heat Number",
    name: "heat_no",
    type: "input",
    required: true,
    quarter: true,
  },
  {
    label: "Billet Production Date",
    name: "billet_production_date",
    type: "datetime-select",
    required: true,
  },
];

const CHEMICAL_COMPOSITION_FORM = [
  {
    label: "Carbon (C)",
    name: "carbon",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Silicon (Si)",
    name: "silicon",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Manganese (Mn)",
    name: "manganese",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Phosphorus (P)",
    name: "phosphorus",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Sulphur (S)",
    name: "sulphur",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Copper (Cu)",
    name: "copper",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Nickel (Ni)",
    name: "nickel",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Chromium (Cr)",
    name: "chromium",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Vanadium (V)",
    name: "vanadium",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Aluminium (AI)",
    name: "aluminium",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Molybdenum (Mo)",
    name: "molybdenum",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Tin (Sn)",
    name: "tin",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Boron (B)",
    name: "boron",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Nitrogen (N)",
    name: "nitrogen",
    type: "input-number",
    quarter: true,
    required: true,
  },
  {
    label: "Cerium (CE)",
    name: "cerium",
    type: "input-number",
    quarter: true,
    required: true,
    disabled: true,
  },
];

export default function ChemicalModalForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [chemicalForm] = Form.useForm();

  const onFinish = (value) => {
    if (editData) {
      onSubmit(value, editData["key"]);
    } else onSubmit(value, null);
  };

  const handleFieldChange = () => {
    // CEV (%) = %C + %Mn/6 + (%Cu + %Ni)/15 + (%Cr + %Mo + %V)/5
    const carbonValue = chemicalForm.getFieldValue("carbon");
    const manganeseValue = chemicalForm.getFieldValue("manganese");
    const copperValue = chemicalForm.getFieldValue("copper");
    const nickelValue = chemicalForm.getFieldValue("nickel");
    const chromiumValue = chemicalForm.getFieldValue("chromium");
    const molybdenumValue = chemicalForm.getFieldValue("molybdenum");
    const vanadiumValue = chemicalForm.getFieldValue("vanadium");

    const allFieldDefinend = [
      carbonValue,
      manganeseValue,
      copperValue,
      nickelValue,
      chromiumValue,
      molybdenumValue,
      vanadiumValue,
    ].every((value) => value !== undefined);

    if (allFieldDefinend) {
      const ceqValue =
        carbonValue +
        manganeseValue / 6 +
        (copperValue + nickelValue) / 15 +
        (chromiumValue + molybdenumValue + vanadiumValue) / 5;

      chemicalForm.setFieldValue("cerium", ceqValue.toFixed(2));
    }
  };

  const handleClearForm = () => chemicalForm.resetFields();

  useEffect(() => {
    if (modalOpen == false) {
      chemicalForm.resetFields();
      setEditData(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (editData) chemicalForm.setFieldsValue(editData);
  }, [editData]);

  return (
    <CustomModal
      title={
        editData ? "Edit Chemical Composition" : "Add Chemical Composition"
      }
      close_icon={true}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <Form
          form={chemicalForm}
          onFinish={onFinish}
          onFieldsChange={handleFieldChange}
        >
          <Divider
            orientation="left"
            orientationMargin="0"
            children={<strong>Heat Number</strong>}
            style={{ borderColor: "#C0C0C0" }}
          />
          <Row gutter={[16, 16]}>
            <CustomFormItems form_properties={HEAT_NUMBER_FORM} />
          </Row>
          <Divider
            orientation="left"
            orientationMargin="0"
            children={<strong>Chemical Composition</strong>}
            style={{ borderColor: "#C0C0C0" }}
          />
          <Row gutter={[16, 16]}>
            <CustomFormItems form_properties={CHEMICAL_COMPOSITION_FORM} />
          </Row>
          <Flex gap="middle" justify="flex-end">
            <Form.Item>
              <Button
                onClick={handleClearForm}
                type="link"
                children="Clear All"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                children={editData ? "Update" : "Create"}
                loading={loading}
              />
            </Form.Item>
          </Flex>
        </Form>
      }
    />
  );
}
