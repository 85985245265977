import { Col, Image, Row, Typography } from "antd";
import CustomModal from "../common/CustomModal";
import DataTable from "../common/DataTable";

const millCertificateDetailColumns = [
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    width: 70,
  },
  {
    title: "Heat No",
    dataIndex: "heat_no",
    key: "heat_no",
    width: 80,
  },
  {
    title: "Mass/Meter (kg/m)",
    dataIndex: "mass_meter",
    key: "mass_meter",
    width: 140,
  },
  {
    title: "No. of Bundles",
    dataIndex: "number_of_bundles",
    key: "number_of_bundles",
    width: 120,
  },
];

const mechanicalPropertiesColumns = [
  {
    title: "Bend Test 90deg",
    dataIndex: "bend_test",
    key: "bend_test",
    width: 140,
  },
  {
    title: "Rebend Test",
    dataIndex: "rebend_test",
    key: "rebend_test",
    width: 100,
  },
  {
    title: "Yield (N/mm2)",
    dataIndex: "yield",
    key: "yield",
    width: 120,
  },
  {
    title: "Tensile (N/mm2)",
    dataIndex: "tensile",
    key: "tensile",
    width: 130,
  },
  {
    title: "Elongation (Agt%)",
    dataIndex: "elongation",
    key: "elongation",
    width: 140,
  },
];

const chemicalCompositionColumns = [
  {
    title: "Ceq (%)",
    dataIndex: "ceq",
    key: "ceq",
    width: 80,
  },
  {
    title: "C",
    dataIndex: "carbon",
    key: "carbon",
    width: 80,
  },
  {
    title: "Mn",
    dataIndex: "manganese",
    key: "manganese",
    width: 80,
  },
  {
    title: "P",
    dataIndex: "phosphorus",
    key: "phosphorus",
    width: 80,
  },
  {
    title: "S",
    dataIndex: "sulphur",
    key: "sulphur",
    width: 80,
  },
  {
    title: "Cu",
    dataIndex: "copper",
    key: "copper",
    width: 80,
  },
  {
    title: "Ni",
    dataIndex: "nickel",
    key: "nickel",
    width: 80,
  },
  {
    title: "Cr",
    dataIndex: "chromium",
    key: "chromium",
    width: 80,
  },
  {
    title: "V",
    dataIndex: "vanadium",
    key: "vanadium",
    width: 80,
  },
  {
    title: "Mo",
    dataIndex: "molybdenum",
    key: "molybdenum",
    width: 80,
  },
  {
    title: "N",
    dataIndex: "nitrogen",
    key: "nitrogen",
    width: 80,
  },
];

const PREVIEW_COLUMN_TEMPLATE = {
  normal_mill_cert: [
    {
      title: "Mill Certificate Detail",
      children: millCertificateDetailColumns,
    },
    {
      title: "Mechanical Properties",
      children: mechanicalPropertiesColumns,
    },
    {
      title: "Chemical Composition (%)",
      children: chemicalCompositionColumns,
    },
  ],
  cares_mill_cert: [
    {
      title: "Mill Certificate Detail",
      children: millCertificateDetailColumns,
    },
    {
      title: "Mechanical Properties",
      children: mechanicalPropertiesColumns,
    },
    {
      title: "Chemical Composition (%)",
      children: [
        ...chemicalCompositionColumns,
        {
          title: "B",
          dataIndex: "boron",
          key: "boron",
          width: 80,
        },
      ],
    },
  ],
  acrs_mill_cert: [
    {
      title: "Mill Certificate Detail",
      children: millCertificateDetailColumns,
    },
    {
      title: "Mechanical Properties",
      children: mechanicalPropertiesColumns,
    },
    {
      title: "Chemical Composition (%)",
      children: [
        {
          title: "C",
          dataIndex: "carbon",
          key: "carbon",
          width: 80,
        },
        {
          title: "P",
          dataIndex: "phosphorus",
          key: "phosphorus",
          width: 80,
        },
        {
          title: "S",
          dataIndex: "sulphur",
          key: "sulphur",
          width: 80,
        },
        {
          title: "Ceq (%)",
          dataIndex: "ceq",
          key: "ceq",
          width: 80,
        },
      ],
    },
  ],
};

export default function DeliveryOrderPreviewModal({
  modalOpen,
  setModalOpen,
  summaryData,
  tableData,
  millCertType,
}) {
  return (
    <CustomModal
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={1600}
      title={`Mill Cert - ${summaryData.delivery_order_id}`}
      content={
        <>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={6}>
              <h3>Customer</h3>
              <Typography.Text children={summaryData.customer} />
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
              <h3>Specification</h3>
              <Typography.Text children={summaryData.specification} />
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
              <h3>Description of Good</h3>
              <Typography.Text children={summaryData.description} />
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} />
            <Col xs={24} sm={12} md={12} lg={6}>
              <h3>Address</h3>
              {summaryData.address &&
                summaryData.address.split("\n").map((line, index) => (
                  <Typography.Text key={index}>
                    {line}
                    <br />
                  </Typography.Text>
                ))}
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
              <h3>Bar Mark</h3>
              {summaryData.bar_mark &&
                summaryData.bar_mark.map((src) => {
                  return <Image src={src} />;
                })}
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col>
              <DataTable
                table_column={PREVIEW_COLUMN_TEMPLATE[millCertType]}
                table_data={tableData}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
}
