import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useUpdateDOProgress() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [updateLoading, setUpdateLoading] = useState(false);

  const updateDOProgress = useCallback(
    (key, handleDOProgressList) => {
      return new Promise((resolve, reject) => {
        setUpdateLoading(true);
        fetchWrapper({
          endpoint_url: "do_progress/updateDOProgress",
          params: {
            key: key,
          },
          setLoading: setUpdateLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              handleDOProgressList();
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content:
                "Network Error. Failed to update delivery order progress",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Update Delivery Order Progress), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { updateDOProgress, updateLoading };
}

export default useUpdateDOProgress;
