import { useContext, useState } from "react";
import { IndexContext } from "../../../context/IndexContext";
import QrScannerFunc from "../../../components/ai_rebar_count/qrScanner/QrScannerFunc";
import { Flex } from "antd";

// Serial Number New Format (With Factory)
const pattern = /^[A-Z][A-Z]-\d{2}-[A-Z]-\d{6}-\d{4}$/;
// Serial Number Old Format
const oldPattern = /^\d{2}-[A-Z]-\d{6}-\d{4}$/;

export default function QrScannerTest() {
  const { messageApi } = useContext(IndexContext);

  const [isLoading, setIsLoading] = useState(false);

  // Function to Submit the QR Scan Result
  const handleScanResultConfirm = (value, resetQrScanner) => {
    const serialNumber = value.split(",")[1].replace(" ", "");

    if (isValidFormat(serialNumber)) {
      messageApi.open({
        type: "success",
        content: "Correct Serial Number Format",
      });
      resetQrScanner();
    } else {
      messageApi.open({
        type: "warning",
        content: "Invalid Serial Number Format",
      });
    }
  };

  // To check is the QR Scan Result is Valid Format or not
  const isValidFormat = (value) => {
    return pattern.test(value) || oldPattern.test(value);
  };

  return (
    <Flex justify="center">
      <div className="responsive-row">
        <QrScannerFunc
          isLoading={isLoading}
          handleConfirm={handleScanResultConfirm}
        />
      </div>
    </Flex>
  );
}
